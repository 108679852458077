import { cx } from "@emotion/css";
import type { FC, InputHTMLAttributes } from "react";

import { Loading } from "../loading";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  styleType?: "shadow" | "outline";
  isLoading?: boolean;
  error?: string;
}
const Input: FC<InputProps> = ({
  className,
  styleType = "outline",
  isLoading,
  error,
  ...props
}) => {
  return (
    <div className={cx("relative flex flex-col", className)}>
      <input
        className={cx([
          "form-input rounded-lg p-3 text-sm outline-0",
          "focus:border-transparent focus:ring-primary",
          {
            "border-none bg-[#F2F4F5]": styleType === "shadow",
            "border-solid border-[#E3E5E5]": styleType === "outline",
          },
          props.disabled ?? "pr-8",
          error && "!border-solid !border-error",
        ])}
        style={{ boxShadow: "0 0 #0000" }}
        {...props}
      />
      {isLoading && <Loading className="absolute right-0 h-6 p-4" />}
      <p className="mt-2 h-5 text-sm text-error">{error}</p>
    </div>
  );
};

export default Input;
