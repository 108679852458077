import { cx } from "@emotion/css";

interface LabelProps {
  htmlFor: string;
  children: string;
  required?: boolean;
  className?: string;
}

export const Label: React.FC<LabelProps> = ({
  children,
  className,
  required,
  ...props
}) => {
  return (
    <label className={cx("mb-2 block text-sm leading-6", className)} {...props}>
      {children}
      {required && <span className="text-error">（必須）</span>}
    </label>
  );
};
