import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import type {
  AdmissionType,
  DayOfWeek,
  UserCategory,
  ValidDurationValue,
} from "@/types";

import type { RootState } from "../type";

export const BizKeyApplicationSliceName = "bizkey";

export interface BizKeyApplicationState {
  application_type?: UserCategory;
  property?: {
    value: string;
    label: string;
  };
  start_date?: string;
  time?: [string[], string[]];
  day_of_week?: {
    value: DayOfWeek;
    label: string;
  }[];
  access_method?: AdmissionType;
  valid_duration?: {
    value: ValidDurationValue;
    label: string;
  };
  room_number?: string;
  comment?: string;
  selectedApplicationId?: string;
}

const initialState: BizKeyApplicationState = {
  application_type: undefined,
  property: undefined,
  access_method: "onetime",
  time: [[], []],
  day_of_week: [],
};

const bizKeyApplicationSlice = createSlice({
  name: BizKeyApplicationSliceName,
  initialState,
  reducers: {
    updateBizKeyApplication: (
      state,
      action: PayloadAction<BizKeyApplicationState>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSelectApplicationId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        selectedApplicationId: action.payload,
      };
    },
    clearBizKeyApplication: () => {
      return initialState;
    },
  },
});

export const {
  updateBizKeyApplication,
  setSelectApplicationId,
  clearBizKeyApplication,
} = bizKeyApplicationSlice.actions;

export const useSelectBizKeyApplication = () => {
  return useSelector((state: RootState) => state[BizKeyApplicationSliceName]);
};

export default bizKeyApplicationSlice.reducer;
