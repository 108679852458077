import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BizKeyLOGO, Button, Card, Footer, Main, Return } from "@/components";
import { clearBizKeyApplication } from "@/redux/slices/bizkey";
import type { UserCategory } from "@/types";

const Apply = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(clearBizKeyApplication());

  const handleToBasicInfo = (type: UserCategory) => {
    navigate(`/apply/${type}`);
  };
  return (
    <>
      <Main>
        <BizKeyLOGO />
        <Card>
          <header className="text-sm font-medium">申請者について</header>
          <section className="mt-4 text-neutral-400">
            <p className="text-xxs leading-6">お選びください</p>
            <Button
              type="default"
              onClick={() => handleToBasicInfo("services")}
            >
              サービス事業者
            </Button>

            <Button
              type="default"
              className="!mt-2"
              onClick={() => handleToBasicInfo("resident")}
            >
              居住者
            </Button>
          </section>
        </Card>
        <Return className="mt-2" onClick={() => navigate(-1)} />
      </Main>
      <Footer />
    </>
  );
};

export default Apply;
