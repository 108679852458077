import { cx } from "@emotion/css";
import {
  ConfigProvider,
  Loading as NutLoading,
  Overlay,
} from "@nutui/nutui-react";

interface LoadingProps {
  color?: string;
  className?: string;
  size?: string;
  type?: "spinner" | "circular";
}
export const Loading = ({
  className,
  color = "#8c8c8c",
  size = "1rem",
  type = "spinner",
}: LoadingProps) => {
  return (
    <ConfigProvider
      className={cx("mx-auto flex justify-center", className)}
      theme={{
        nutuiLoadingIconColor: color,
        nutuiLoadingIconSize: size,
      }}
    >
      <NutLoading type={type} />
    </ConfigProvider>
  );
};

export const OverlayLoading = ({ visible }: { visible: boolean }) => {
  return (
    <Overlay closeOnOverlayClick={false} visible={visible}>
      <div className="flex h-full w-full items-center justify-center">
        <Loading color="#fff" />
      </div>
    </Overlay>
  );
};
