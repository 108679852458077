import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import QRCode from "react-qr-code";

import AlertIcon from "@/assets/orange-alert.svg?react";
import { CodeBoard, Countdown, Loading } from "@/components";
import { useGenerateTotp } from "@/hooks";
import { BIZKEY_KEY, useAuth } from "@/queries";
import { useSelectBizKeyApplication } from "@/redux/slices/bizkey";
import { inValidationDuration } from "@/shared/utils";

interface EntryCodeDialogProps {
  visible: boolean;
}
export const EntryCodeDialog: React.FC<EntryCodeDialogProps> = ({
  visible,
}) => {
  if (!visible) return null;
  else return <EntryCodeDialogContent />;
};

export const EntryCodeDialogContent = () => {
  const [isExpired, setIsExpired] = useState(false);

  const queryClient = useQueryClient();
  const { selectedApplicationId } = useSelectBizKeyApplication();
  const { otp, timeRemaining, freshTotp, application, isLoading, isSuccess } =
    useGenerateTotp(selectedApplicationId, isExpired);
  const { data, isSuccess: isAuthSuccess, isLoading: isAuthing } = useAuth();

  const handleEntryCodeExpired = () => {
    const isExpired = !inValidationDuration(application);
    if (isExpired) {
      setIsExpired(true);
      queryClient.invalidateQueries({ queryKey: [BIZKEY_KEY] });
    } else {
      freshTotp();
    }
  };

  if (isExpired || application?.status === "expired") {
    return (
      <>
        <div className="mx-auto h-48 w-48 bg-neutral-200/10 p-12">
          <AlertIcon className="m-auto h-20 w-20" />
        </div>
        <div className="mb-9 mt-10">
          <p className="text-center text-xs text-error">
            許可された時間を超えています。
          </p>
        </div>
      </>
    );
  }

  if (isLoading && isAuthing) {
    return (
      <>
        <div className="mx-auto h-48 w-48 bg-neutral-200/10 p-12">
          <Loading size="6rem" type="circular" />
        </div>
        <div className="text-xs">
          <div className="my-5 text-center leading-4">00</div>
          <p className="text-neutral-1000">
            スキャナーがない場合は、以下のパスワードを入力してください。
          </p>
          <CodeBoard className="my-1">{"「------」"}</CodeBoard>
          <p className="text-xxs text-neutral-100">
            このパスワードは60秒ごとに更新されます。
          </p>
        </div>
      </>
    );
  }
  if (isSuccess && isAuthSuccess) {
    return (
      <>
        <QRCode
          value={JSON.stringify({
            customer: data?.data.user.id,
            entryCode: otp,
          })}
          className="mx-auto h-48 w-48"
        />

        <div className="text-xs">
          <Countdown
            key={otp}
            duration={timeRemaining()}
            onCoutdownEnd={handleEntryCodeExpired}
            className="my-5 justify-center"
          />
          <p className="text-neutral-1000">
            スキャナーがない場合は、以下のパスワードを入力してください。
          </p>
          <CodeBoard className="my-1">{otp}</CodeBoard>
          <p className="text-xxs text-neutral-100">
            このパスワードは60秒ごとに更新されます。
          </p>
        </div>
      </>
    );
  }
};
