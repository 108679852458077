import { useInfiniteQuery } from "@tanstack/react-query";

import { getBizKeys, type GETBizKeysParams } from "@/requests";
import type { ApplicationStatus } from "@/types";

import { BIZKEY_KEY } from "..";

export function useInfiniteListBizKey(
  status: ApplicationStatus = "expired",
  enabled = true,
  is_entry_list?: boolean
) {
  const queryFn = async (params: GETBizKeysParams) => {
    return getBizKeys(params);
  };
  const params = {
    status,
    page: 1,
    page_size: 10,
    is_entry_list,
  };
  return useInfiniteQuery({
    queryKey: [BIZKEY_KEY, status, params],
    queryFn: ({ pageParam }) => {
      return queryFn(pageParam);
    },
    enabled,
    staleTime: 1000 * 60 * 5,
    initialPageParam: params,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (allPages.length >= lastPage.data.total_pages) return undefined;
      return {
        ...lastPageParam,
        page: lastPageParam.page + 1,
      };
    },
  });
}
