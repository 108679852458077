import ArrowIcon from "@/assets/return-down.svg?react";
import { useModal } from "@/hooks";
import type { InputOption } from "@/types";

import type { InputProps } from "./Input";
import Input from "./Input";
import { Popup } from "./Popup";

export interface StaticSelectInputProps extends InputProps {
  options: InputOption[];
  // eslint-disable-next-line no-unused-vars
  handleOptionClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}
export const StaticSelectInput: React.FC<StaticSelectInputProps> = ({
  options,
  handleOptionClick,
  ...props
}) => {
  const { visible, toggle } = useModal();
  return (
    <div className="relative">
      <ArrowIcon className="absolute right-3 top-0 z-20 my-4 h-4 w-4" />
      <Input readOnly onClick={toggle} {...props} />
      <Popup
        visible={visible}
        onClick={toggle}
        onClose={() => {
          visible && toggle();
        }}
        onOverlayClick={() => {
          visible && toggle();
        }}
      >
        <p className="p-5 pb-0 text-center text-base">{props.placeholder}</p>
        <div
          className="divide-x-0 divide-y divide-solid divide-[#E9EDEE] p-4"
          onClick={handleOptionClick}
        >
          {options?.map((optionValue, index) => (
            <p
              data-option-value={optionValue.value}
              className="text-text-gray text-center text-base leading-12"
              key={index}
            >
              {optionValue.label}
            </p>
          ))}
        </div>
      </Popup>
    </div>
  );
};
