import { cx } from "@emotion/css";

interface FormControlProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}
export const FormControl: React.FC<FormControlProps> = ({
  children,
  className,
}) => {
  return <div className={cx("mt-5", className)}>{children}</div>;
};
