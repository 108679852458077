import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { TOKYO_TIMEZONE } from "@/app.constant";
import type { BizKeyModal, DayOfWeek } from "@/types";

export const inValidationDuration = (application?: BizKeyModal): boolean => {
  if (application === undefined) return false;
  const {
    application_type,
    template: { access_method },
  } = application;
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isBetween);

  if (application_type === "resident" || access_method === "onetime") {
    const now = dayjs.utc().tz(TOKYO_TIMEZONE);
    const start_day = dayjs
      .utc(application.credential?.start_at)
      .tz(TOKYO_TIMEZONE);
    const end_day = dayjs
      .utc(application.credential?.expired_at)
      .tz(TOKYO_TIMEZONE);
    return now.isBetween(start_day, end_day);
  } else if (application_type === "services" && access_method === "repeat") {
    const now = dayjs.utc().tz(TOKYO_TIMEZONE);

    const start_day = dayjs
      .utc(application.credential?.start_at)
      .tz(TOKYO_TIMEZONE);
    const end_day = dayjs
      .utc(application.credential?.expired_at)
      .tz(TOKYO_TIMEZONE);

    const day = dayjs.utc().tz(TOKYO_TIMEZONE).day() as DayOfWeek;
    const isDay = application.template.day_of_week?.includes(day);

    const start_time = dayjs
      .utc(application.credential?.time_start)
      .format("HH:mm");
    const end_time = dayjs
      .utc(application.credential?.time_end)
      .format("HH:mm");

    const now_2 = dayjs.utc(now.add(1, "hour"));
    const date = now.format("YYYY-MM-DD");
    const reSetDateStartTime = dayjs(date + start_time);
    const reSetDateEndTime = dayjs(date + end_time);
    const isTimeBetween = now_2.isBetween(
      reSetDateStartTime,
      reSetDateEndTime,
      "minute",
      "[)"
    );
    return now.isBetween(start_day, end_day) && isDay && isTimeBetween;
  }
  return false;
};

export function today(): string {
  return dayjs().format("YYYY-MM-DD");
}
export function tomorrow(): string {
  return dayjs().add(1, "day").format("YYYY-MM-DD");
}
