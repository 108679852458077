import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { weekdaysOptions } from "@/app.constant";
import type { MultiSelectInputProps } from "@/components/form";
import { MultiSelectInput } from "@/components/form";
import type { InputOption } from "@/types";

interface WeekdayInputControl
  extends Pick<MultiSelectInputProps, "id" | "placeholder"> {
  name: string;
  control: Control<any>;
}
export const WeekdayInputControl: React.FC<WeekdayInputControl> = ({
  name,
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          required: (value) => value?.length > 0,
        },
      }}
      render={({ field: { value, onChange } }) => {
        const handleOptionClick = (e: React.MouseEvent<HTMLDivElement>) => {
          const optionEle = e.target as HTMLLabelElement;
          const optionValue = Number(optionEle.dataset.optionValue);
          if (!Number.isNaN(optionValue)) {
            const isItemExists = value?.some(
              (item: { value: number }) => item.value == optionValue
            );
            if (isItemExists) {
              onChange(
                value?.filter(
                  (item: { value: number }) => item.value != optionValue
                )
              );
            } else {
              onChange([
                ...value,
                {
                  value: optionValue,
                  label: optionEle.textContent,
                },
              ]);
            }
          }
        };
        return (
          <MultiSelectInput
            value={value.map((item: InputOption) => item.label)}
            options={weekdaysOptions}
            handleOptionClick={handleOptionClick}
            {...props}
          />
        );
      }}
    />
  );
};
