import Logo from "@/assets/logo.svg?react";

export const BizKeyLOGO = () => {
  return (
    <div className="mb-3 mt-5 flex items-center gap-2">
      <Logo />
      <div className="text-sm font-semibold leading-5">
        <p>Pabbit BizKey</p>
        <p className="flex justify-between">
          <span>入</span>
          <span>館</span>
          <span>申</span>
          <span>請</span>
        </p>
      </div>
    </div>
  );
};
