import { cx } from "@emotion/css";

export const Dashline = ({ active }: { active?: boolean }) => (
  <hr
    className={cx(
      "mt-2 grow border border-dashed",
      active ? "border-primary" : "border-neutral-300"
    )}
  />
);
