import { useMutation } from "@tanstack/react-query";

import { verifyCode } from "@/requests";

export const useSignIn = () => {
  return useMutation({ mutationFn });
};

const mutationFn = async ({ code }: { code: string }) => {
  return verifyCode({ code });
};
