import { useMutation, useQuery } from "@tanstack/react-query";

import { getBizKeyById } from "@/requests/service";

import { BIZKEY_KEY } from "..";

export const useGetBizKeyById = (application_id: string | undefined) => {
  const queryFn = () => {
    if (!application_id) {
      throw new Error("application_id is required");
    }
    return getBizKeyById(application_id);
  };
  return useQuery({
    queryKey: [BIZKEY_KEY, application_id],
    queryFn: queryFn,
    enabled: !!application_id,
  });
};

export const useGetBizKeyByIdManual = () => {
  const mutationFn = (application_id: string) => {
    return getBizKeyById(application_id);
  };
  return useMutation({ mutationFn });
};
