import { cx } from "@emotion/css";
import React from "react";
import { type Control, Controller } from "react-hook-form";

interface AdmissionTypeInputControlProps {
  name: string;
  control: Control<any>;
}
export const AdmissionTypeInputControl: React.FC<
  AdmissionTypeInputControlProps
> = ({ name, control }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={"onetime"}
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => {
        return (
          <div className="mb-7 flex gap-3">
            <AdmmisionTypeRadio
              value="onetime"
              selectedValue={value}
              onChange={onChange}
            >
              一回限り
            </AdmmisionTypeRadio>

            <AdmmisionTypeRadio
              value="repeat"
              selectedValue={value}
              onChange={onChange}
            >
              定期的に
            </AdmmisionTypeRadio>
          </div>
        );
      }}
    />
  );
};
interface AdmmisionTypeRadioProps {
  value: string;
  selectedValue: string;
  onChange: () => void;
  className?: string;
  children?: string;
}

const AdmmisionTypeRadio: React.FC<AdmmisionTypeRadioProps> = ({
  value,
  selectedValue,
  onChange,
  className,
  children,
}) => {
  return (
    <label
      className={cx(
        "block h-12 flex-1",
        "rounded-md",
        "text-center font-medium leading-12 text-neutral-300",
        "border border-solid border-neutral-300",
        value === selectedValue && "border-primary text-primary ",
        className
      )}
    >
      <input
        type="radio"
        value={value}
        checked={value === selectedValue}
        onChange={onChange}
        className="invisible absolute"
      />
      {children}
    </label>
  );
};
