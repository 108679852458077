import type { Control } from "react-hook-form";
import { Controller, useController } from "react-hook-form";

import type { SelectInputProps } from "@/components/form";
import { SelectInput } from "@/components/form";
import { useInfiniteListProperty } from "@/queries";
import { noti } from "@/shared/utils";

interface PropertyInputControlProps
  extends Pick<SelectInputProps, "placeholder" | "id"> {
  name: string;
  control: Control<any>;
}

export const PropertyInputControl: React.FC<PropertyInputControlProps> = ({
  name,
  control,
  ...props
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules: {
      required: true,
    },
  });
  const { data, hasNextPage, fetchNextPage, isLoading, isError, error } =
    useInfiniteListProperty();
  const options = data?.pages.flatMap((page) => {
    return page.data.properties?.map((property) => ({
      value: property.id,
      label: property.name,
    }));
  });

  const handleOptionClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const optionEle = e.target as HTMLParagraphElement;
    if (optionEle.dataset.optionValue) {
      onChange({
        value: optionEle.dataset.optionValue,
        label: optionEle.textContent,
      });
    }
  };

  if (isError) {
    noti(error?.message || error.toString());
  }

  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <SelectInput
            value={value?.label}
            {...props}
            options={options || []}
            optionsLoading={isLoading}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            handleOptionClick={handleOptionClick}
          />
        );
      }}
    />
  );
};
