import { cx } from "@emotion/css";

export const Footer = ({ className }: { className?: string }) => {
  return (
    <footer
      className={cx("pb-8 text-center text-sm text-neutral-200", className)}
    >
      <p>© 2024 PacPort. All rights reserved</p>
    </footer>
  );
};
