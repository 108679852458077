import * as Sentry from "@sentry/react";

export function initSentry() {
  Sentry.init({
    dsn: "https://49068f47a36471c158b05ce6965ccd0c@o4506630185680896.ingest.us.sentry.io/4507252538081280",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [[400, 599]],
      }),
    ],
    ignoreErrors: [],
    environment: import.meta.env.MODE,
    sendDefaultPii: true,

    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
