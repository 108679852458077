import { cx } from "@emotion/css";

interface CardProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const Card: React.FunctionComponent<CardProps> = ({
  children,
  className,
}) => {
  return (
    <section className={cx("rounded-lg bg-white p-2 py-4", className)}>
      {children}
    </section>
  );
};
