import { Amplify } from "aws-amplify";
import {
  confirmSignIn,
  deleteUser,
  fetchAuthSession,
  signIn,
  signOut,
} from "aws-amplify/auth";
import type { AxiosResponse } from "axios";

import { server } from "@/requests";
import type { UserInfo } from "@/types";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_USER_POOL_APP_CLIENT_ID,
    },
  },
});

export const createUser = async (data: { email: string }) => {
  return server.post("/customers", data);
};

export const sendCode = async ({ username }: { username: string }) => {
  return signIn({
    username,
    options: {
      authFlowType: "CUSTOM_WITHOUT_SRP",
    },
  });
};

export const verifyCode = async ({ code }: { code: string }) => {
  return confirmSignIn({ challengeResponse: code });
};

export const auth = async () => {
  return server.get<unknown, AxiosResponse<UserInfo>>("/auth");
};

export const getUserInfo = async (params?: { enforced?: boolean }) => {
  const enforced = params?.enforced || false;
  return await fetchAuthSession({ forceRefresh: enforced });
};

export const logout = async () => {
  return signOut();
};

export const unregisterUser = async () => {
  return deleteUser();
};

export const checkTokenExpiration = async () => {
  try {
    const session = await fetchAuthSession();
    const idToken = session.tokens?.idToken;
    const exp = idToken?.payload.exp;

    const currentTime = Math.floor(new Date().getTime() / 1000);
    if (exp && currentTime < exp) {
      return idToken?.payload.sub;
    } else {
      throw new Error("Token expired");
    }
  } catch (e) {
    throw new Error("Error getting current user session");
  }
};
