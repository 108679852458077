import type { TabsProps as NutTapsProps } from "@nutui/nutui-react";
import { Tabs as NutTabs } from "@nutui/nutui-react";

interface TabsProps extends Pick<NutTapsProps, "value" | "onChange"> {
  children: React.ReactNode;
}

export const Tabs: React.FC<TabsProps> = ({ children, ...props }) => {
  return <NutTabs {...props}>{children}</NutTabs>;
};
