import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  ApplyInfoCard,
  ApplyInfoCardBody,
  ApplyInfoCardBodyItem,
  ApplyInfoCardHeader,
  Button,
  OverlayLoading,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { UserInfoCard } from "@/components/info-card/UserInfoCard";
import { useModal } from "@/hooks";
import {
  OverlapEntryTimeError,
  useAuth,
  useCreateBizKeyOfResident,
} from "@/queries";
import { useSelectBizKeyApplication } from "@/redux/slices/bizkey";
import { noti } from "@/shared/utils";

interface ResidentApplyConfirmProps {}

export const ResidentApplyConfirm: React.FC<ResidentApplyConfirmProps> = () => {
  const applicationDetail = useSelectBizKeyApplication();
  const navigate = useNavigate();
  const { visible, toggle } = useModal();
  const { mutateAsync, isPending } = useCreateBizKeyOfResident();
  const { data, isFetching, isError, error } = useAuth();
  const user = data?.data;
  const handleComfirmClick = async () => {
    mutateAsync()
      .then(() => {
        navigate("../success");
      })
      .catch((error) => {
        if (error instanceof OverlapEntryTimeError) {
          toggle();
          return;
        }
        noti(error?.message || error.toString());
      });
  };
  // get auth info failed, but should not block the process
  if (isError) {
    noti(error?.message || error.toString());
  }

  useEffect(() => {
    if (!applicationDetail.property) {
      navigate("/apply/resident");
    }
  }, [applicationDetail]);

  return (
    <>
      <div className="flex flex-col">
        <UserInfoCard user={user} type="resident" canEdit />
        <ApplyInfoCard className="mt-4">
          <ApplyInfoCardHeader className="flex justify-between text-sm">
            <p className="font-medium">申請内容</p>
            <button
              className="text-xxs text-primary"
              onClick={() => navigate(-1)}
            >
              修正
            </button>
          </ApplyInfoCardHeader>
          <ApplyInfoCardBody>
            <ApplyInfoCardBodyItem title="物　　件：">
              <span>{applicationDetail?.property?.label}</span>
            </ApplyInfoCardBodyItem>
            <ApplyInfoCardBodyItem title="部屋番号：">
              <span>{applicationDetail?.room_number}</span>
            </ApplyInfoCardBodyItem>
            <ApplyInfoCardBodyItem title="コメント：">
              <span>{applicationDetail?.comment || "無し"}</span>
            </ApplyInfoCardBodyItem>
          </ApplyInfoCardBody>
        </ApplyInfoCard>
      </div>
      <div className="mt-auto">
        <Button onClick={handleComfirmClick} loading={isPending}>
          確認して提出する
        </Button>
        <p className="mt-2 text-xs text-neutral-100">
          ※&nbsp;祝日に申請した場合、承認が遅れることがあります。
        </p>
      </div>
      <OverlayLoading visible={isFetching} />
      <Popup visible={visible}>
        <PopupMain>
          時間帯が既存の申請と重なっているため、重複して申請を作成することはできません。
        </PopupMain>
        <PopupAction>
          <Button onClick={toggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
};
