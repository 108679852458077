import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  BizKeyLOGO,
  Button,
  Card,
  Footer,
  Label,
  Main,
  Return,
} from "@/components";
import { useAuth } from "@/queries";
import { useUpdateCustomer } from "@/queries/mutation/useUpdateCustomer";
import { updateUser } from "@/redux/slices/user";
import { noti } from "@/shared/utils";

import { CorporationInputControl, NameInputControl } from "../components";

interface AgentFormFields {
  corporation: string;
  name: string;
}
export const AgentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useAuth();
  const user = data?.data.user;
  const {
    control,
    handleSubmit,
    formState: { isValid, defaultValues },
  } = useForm<AgentFormFields>({
    defaultValues: {
      name: user?.nickname,
      corporation: user?.company_name,
    },
  });
  const { mutateAsync: saveUserInfo, isPending } = useUpdateCustomer();
  const handleSaveClick = async (values: AgentFormFields) => {
    const { name, corporation } = values;
    dispatch(
      updateUser({
        user: { nickname: name, company_name: corporation },
        user_type: "services",
      })
    );
    await saveUserInfo()
      .then(() => {
        noti("保存しました。");
        if (!defaultValues?.name || !defaultValues?.corporation) {
          navigate("/apply/services", { replace: true });
        } else {
          navigate(-1);
        }
      })
      .catch((error) => {
        noti(error?.message || error.toString());
      });
  };
  return (
    <>
      <Main>
        <BizKeyLOGO />
        <Card className="flex flex-col">
          <div>
            <Label htmlFor="" required>
              会社名
            </Label>
            <CorporationInputControl
              id="corporation"
              name="corporation"
              control={control}
            />
          </div>
          <div>
            <Label htmlFor="" required>
              名前
            </Label>
            <NameInputControl id="name" name="name" control={control} />
          </div>
          <div className="mt-5">
            <Button
              disabled={!isValid}
              loading={isPending}
              onClick={handleSubmit(handleSaveClick)}
            >
              保存する
            </Button>
          </div>
        </Card>
        <Return className="mt-2" onClick={() => navigate(-1)} />
      </Main>
      <Footer />
    </>
  );
};
