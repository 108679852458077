import { PageNotFound } from "@/components";
import { useGetUserCategory } from "@/hooks";

import { AgentApplyConfirm } from "./AgentApplyConfirm";
import { ResidentApplyConfirm } from "./ResidentApplyConfirm";

export default function ApplyEntryConfirmWrapper() {
  const category = useGetUserCategory();
  switch (category) {
    case "services":
      return <AgentApplyConfirm />;
    case "resident":
      return <ResidentApplyConfirm />;
    default:
      return <PageNotFound />;
  }
}
