import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import type { CalendarInputProps } from "@/components/form";
import { CalendarInput } from "@/components/form";
import { tomorrow } from "@/shared/utils";

interface CalendarInputControlProps extends Pick<CalendarInputProps, "id"> {
  name: string;
  control: Control<any>;
}
export const CalendarInputControl: React.FC<CalendarInputControlProps> = ({
  name,
  control,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue: tomorrow(),
  });
  const handleConfirmClick = (param: string) => {
    const date = `${param[0]}-${param[1]}-${param[2]}`;
    onChange(date);
  };
  return (
    <CalendarInput value={value} handleConfirmClick={handleConfirmClick} />
  );
};
