import { useGetUserCategory } from "@/hooks";
import { noti } from "@/shared/utils";

import { AgencyApplyDetail, ResidentApplyDetail } from "./apply-entry";

const EntryApplyDetail = () => {
  const catagory = useGetUserCategory();
  switch (catagory) {
    case "services":
      return <AgencyApplyDetail />;
    case "resident":
      return <ResidentApplyDetail />;
    default: {
      noti("カテゴリーが不明です");
      return <></>;
    }
  }
};

export default EntryApplyDetail;
