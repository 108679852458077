import React from "react";
import { useNavigate } from "react-router-dom";

import { Return } from "..";

interface HeaderProps {
  children: React.ReactNode | React.ReactNode[];
  canBack?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  children,
  canBack = false,
}) => {
  return (
    <header className="relative h-14 bg-white text-center text-base font-semibold leading-14">
      {canBack && <UnPureReturn />}
      {children}
    </header>
  );
};

const UnPureReturn = () => {
  const navigate = useNavigate();
  return (
    <Return
      className="absolute left-0 top-0 z-20 ml-4 mt-4.5 !text-neutral-900"
      onClick={() => navigate(-1)}
    />
  );
};
