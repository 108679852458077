import { PageNotFound } from "@/components";
import { useGetUserCategory } from "@/hooks";

import { AgentForm, ResidentForm } from "./identity-info";

const IdentityInfoWrapper = () => {
  const category = useGetUserCategory();
  switch (category) {
    case "services":
      return <AgentForm />;
    case "resident":
      return <ResidentForm />;
    default:
      return <PageNotFound />;
  }
};

export default IdentityInfoWrapper;
