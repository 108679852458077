import { Popup as NutPopup } from "@nutui/nutui-react";
import ReactDOM from "react-dom";
interface PopupProps {
  visible: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  onClick?: () => void;
  onOverlayClick?: () => void;
}
export const Popup: React.FC<PopupProps> = ({
  visible,
  onClose,
  onClick,
  onOverlayClick,
  children,
  ...props
}) => {
  return ReactDOM.createPortal(
    <NutPopup
      position="bottom"
      visible={visible}
      onClick={onClick}
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      style={{ maxHeight: "80vh" }}
      overlayStyle={{ backgroundColor: "rgba(0,0,0,0.4)" }}
      round
      {...props}
    >
      {children}
    </NutPopup>,
    document.body
  );
};
