import { AuthError } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { BizKeyLOGO, Button, Footer, Label } from "@/components";
import { FormControl } from "@/components/form";
import { usePreLogin } from "@/queries";
import { unregisterUser } from "@/requests";
import { noti } from "@/shared/utils";

import { useLoginContext } from "../useLoginContext";
import { UserNameInputControl } from ".";

interface UserNameForm {
  username: string;
}

export const UserName = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UserNameForm>({
    mode: "onChange",
  });

  const { isSendCodeProvider } = useLoginContext();
  const [, setIsSendCode] = isSendCodeProvider;

  const { mutateAsync: preLogin, isPending } = usePreLogin();

  const handleSendVerifiedCode = (values: UserNameForm) => {
    const { username } = values;
    preLogin({ username })
      .then(() => {
        setIsSendCode(true);
      })
      .catch((error) => {
        if (
          error instanceof AuthError &&
          error?.name === "UserAlreadyAuthenticatedException"
        ) {
          noti("既にログインしています");
          navigate("/", { replace: true });
          return;
        }
        noti(error?.message || error.toString());
      });
  };
  return (
    <>
      <div className="flex-1 bg-white px-8 py-10">
        <BizKeyLOGO />
        <FormControl className="!mt-11">
          <Label htmlFor="username" className="text-sm">
            メールアドレス
          </Label>
          <UserNameInputControl
            id="username"
            name="username"
            control={control}
          />
        </FormControl>

        <FormControl className="!mt-3">
          <Button
            onClick={handleSubmit(handleSendVerifiedCode)}
            loading={isPending}
            disabled={!isValid}
          >
            確認コードを送信
          </Button>
        </FormControl>
        {import.meta.env.MODE === "development" && (
          <FormControl>
            <DeleteUser />
          </FormControl>
        )}
      </div>
      <Footer className="bg-white" />
    </>
  );
};

const DeleteUser = () => {
  const handleDeleteUser = async () => {
    await unregisterUser()
      .then(() => {
        console.log("delete user success");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return <Button onClick={handleDeleteUser}>delete</Button>;
};
