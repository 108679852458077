import { type Control, useController } from "react-hook-form";

import type { TextInputProps } from "@/components/form";
import { TextInput } from "@/components/form";

interface RoomInputControlProps extends TextInputProps {
  name: string;
  control: Control<any>;
}

export const RoomInputControl: React.FC<RoomInputControlProps> = ({
  name,
  control,
  ...props
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: {
      required: "Please enter a room number",
    },
  });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = e.target.value.substring(0, 50);
    onChange(cleaned);
  };
  return <TextInput value={value} onChange={handleInputChange} {...props} />;
};
