import { cx } from "@emotion/css";
import { ConfigProvider, DatePicker } from "@nutui/nutui-react";

import TimeIcon from "@/assets/time.svg?react";
import { useModal } from "@/hooks";
import jp from "@/locales/ja-JP.js";

import type { InputProps } from "./Input";
import Input from "./Input";

export interface TimeInputProps extends InputProps {
  defaultStartTime: string[];
  defaultEndTime: string[];
  // eslint-disable-next-line no-unused-vars
  handleStartConfirmClick: (values: (string | number)[]) => void;
  // eslint-disable-next-line no-unused-vars
  handleEndConfirmClick: (values: (string | number)[]) => void;
  className?: string;
}

export const TimeInput: React.FC<TimeInputProps> = ({
  defaultStartTime,
  defaultEndTime,
  handleStartConfirmClick,
  handleEndConfirmClick,
  ...props
}) => {
  const { visible: startVisible, toggle: startToggle } = useModal();
  const { visible: endVisible, toggle: endToggle } = useModal();
  const startDate = new Date(2020, 0, 1);
  const endDate = new Date(2025, 10, 1);

  return (
    <div className="relative">
      <TimeIcon className="absolute right-3 top-0 z-20 my-4 h-4 w-4" />
      <Input
        readOnly
        value={props.value}
        className="text-transparent"
        {...props}
      />
      <div className="absolute top-[1px] z-20 p-3 text-sm text-neutral-100 ">
        <TimeButton
          time={defaultStartTime}
          onClick={startToggle}
          placeholder="入館時間"
          error={props.error}
        />
        <span>&nbsp;と&nbsp;</span>
        <TimeButton
          time={defaultEndTime}
          onClick={endToggle}
          placeholder="出館時間"
          error={props.error}
        />
      </div>
      <ConfigProvider locale={jp}>
        <DatePicker
          visible={startVisible}
          title="時間を選択する"
          type="hour-minutes"
          startDate={startDate}
          endDate={endDate}
          onClose={startToggle}
          onConfirm={(_options, values) => handleStartConfirmClick(values)}
        />
      </ConfigProvider>
      <ConfigProvider locale={jp}>
        <DatePicker
          visible={endVisible}
          title="時間を選択する"
          type="hour-minutes"
          startDate={startDate}
          endDate={endDate}
          onClose={endToggle}
          onConfirm={(_options, values) => handleEndConfirmClick(values)}
        />
      </ConfigProvider>
    </div>
  );
};

interface TimeButtonProps {
  onClick: () => void;
  time: string[];
  placeholder?: string;
  error?: string;
}

const TimeButton: React.FC<TimeButtonProps> = ({
  time,
  placeholder,
  onClick,
  error,
}) => {
  return (
    <button
      className={cx(
        "underline",
        time && !error && "text-primary",
        time && error && "text-error"
      )}
      onClick={onClick}
    >
      {time?.join(":") || placeholder}
    </button>
  );
};
