import { type Control, useController } from "react-hook-form";

import type { TextareaProps } from "@/components/form";
import { Textarea } from "@/components/form";

interface CommentInputControlProps extends TextareaProps {
  name: string;
  control: Control<any>;
  maxLength?: number;
}

export const CommentInputControl: React.FC<CommentInputControlProps> = ({
  name,
  control,
  maxLength = 200,
  ...props
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const cut = e.target.value.substring(0, maxLength);
    onChange(cut);
  };

  return (
    <Textarea
      value={value}
      onChange={handleChange}
      maxLength={maxLength}
      {...props}
    />
  );
};
