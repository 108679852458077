import { useQuery } from "@tanstack/react-query";

import { getUserInfo } from "@/requests";

import { AUTH_KEY } from "..";

export const useAuth = () => {
  const coutryCode = import.meta.env.VITE_COUNTRY_CODE;
  const queryFn = () => {
    return getUserInfo({ enforced: true });
  };
  return useQuery({
    queryKey: [AUTH_KEY],
    queryFn,
    staleTime: 1000 * 60 * 10,
    // TODO replace in immer.js if needed
    select: (data) => {
      const userData = data.tokens?.idToken?.payload;
      if (userData === undefined) {
        throw new Error("User data is undefined");
      }
      return {
        data: {
          user: {
            ...userData,
            id: userData?.sub as string,
            nickname: userData?.nickname as string,
            company_name: userData?.company_name as string,
            phone_number: (userData?.phone_number as string)?.replace(
              coutryCode,
              ""
            ),
          },
        },
      };
    },
  });
};
