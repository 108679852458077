import type { AxiosResponse } from "axios";
import { memo } from "react";

import {
  ApplyInfoCard,
  ApplyInfoCardBody,
  ApplyInfoCardHeader,
  Button,
  InfiniteLoadingBoard,
} from "@/components";
import type { GETBizKeysResponse } from "@/requests/service";
import { inValidationDuration } from "@/shared/utils";

import { EntryTimeFormatter } from ".";

interface InfiniteEntriesProps {
  applications: AxiosResponse<GETBizKeysResponse>[] | undefined;
  fetchNextPage: any;
  hasNextPage: boolean;
  loading?: boolean;
  // eslint-disable-next-line no-unused-vars
  handleUnlockClick: (applicationId: string) => void;
}

export const InfiniteEntries: React.FC<InfiniteEntriesProps> = memo(
  ({
    applications,
    fetchNextPage,
    hasNextPage,
    loading,
    handleUnlockClick,
  }) => {
    const handleLoadMore = async () => {
      await fetchNextPage();
    };
    return (
      <InfiniteLoadingBoard
        onLoadMore={handleLoadMore}
        hasMore={hasNextPage}
        loading={loading}
      >
        {applications?.map((page) => {
          return page?.data?.applications?.map((application) => {
            const isOpen = inValidationDuration(application);

            return (
              <ApplyInfoCard key={application.id} className="mt-3">
                <ApplyInfoCardHeader className="flex justify-between text-sm">
                  <p className="font-medium">{application.property.name}</p>
                  <button
                    className="text-xxs text-primary"
                    data-application-detail-id={application.id}
                  >
                    詳細
                  </button>
                </ApplyInfoCardHeader>
                <ApplyInfoCardBody>
                  <article>
                    <p className="text-xxs text-neutral-100">入館日時</p>
                    <div className="text-xs leading-5 text-neutral-900">
                      <EntryTimeFormatter application={application} />
                    </div>
                  </article>
                  <Button
                    data-application-unlock-id={application.id}
                    disabled={!isOpen}
                    onClick={() => handleUnlockClick(application.id)}
                  >
                    解錠
                  </Button>
                </ApplyInfoCardBody>
              </ApplyInfoCard>
            );
          });
        })}
      </InfiniteLoadingBoard>
    );
  }
);
