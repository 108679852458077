import { useLayoutEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { LoadingPage } from "@/components";
import { checkTokenExpiration } from "@/requests";
// import { noti } from "@/utils";
export const ProtectedRoute = ({
  redirectPath = "/",
  children,
}: {
  redirectPath: string;
  children: React.ReactNode;
}) => {
  const { loading, authenticated } = useCheckAuth();
  if (loading) return <LoadingPage />;
  if (authenticated) {
    return children;
  } else {
    return <Navigate to={redirectPath} replace />;
  }
};

function useCheckAuth() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const location = useLocation();
  useLayoutEffect(() => {
    if (location.pathname === "/login") {
      setAuthenticated(true);
      setLoading(false);
      return;
    }
    checkTokenExpiration()
      .then(() => {
        setAuthenticated(true);
      })
      .catch(() => {
        // noti("まずログインしてください");
        setAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
      });
  });
  return { loading, authenticated };
}
