import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import type { TextInputProps } from "@/components/form";
import { TextInput } from "@/components/form";

interface CorporationInputControlProps extends TextInputProps {
  name: string;
  control: Control<any>;
}
export const CorporationInputControl: React.FC<
  CorporationInputControlProps
> = ({ name, control, ...props }) => {
  const {
    field: { value, onChange },
  } = useController({ name, control, rules: { required: true } });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cut = e.target.value?.slice(0, 50);
    onChange(cut);
  };

  return <TextInput value={value} onChange={handleChange} {...props} />;
};
