import type { AxiosResponse } from "axios";

import { server } from "@/requests";
import type { PropertyModal } from "@/types";

export interface GetPropertiesParams {
  active_service: string;
  page?: number;
  page_size?: number;
}

interface GetPropertiesResponse {
  properties: PropertyModal[];
  total: number;
  total_pages: number;
}

export const getProperties = async (params: GetPropertiesParams) => {
  return server.get<unknown, AxiosResponse<GetPropertiesResponse>>(
    "/properties",
    {
      params,
    }
  );
};
