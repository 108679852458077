import { cx } from "@emotion/css";
import type { FC, TextareaHTMLAttributes } from "react";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  styleType?: "shadow" | "outline";
  className?: string;
  maxLength?: number;
}

export const Textarea: FC<TextareaProps> = ({
  styleType = "outline",
  maxLength,
  ...props
}) => {
  return (
    <div className="relative">
      <textarea
        className={cx([
          "w-full",
          "placeholder:text-text-gray form-input rounded-lg p-3 text-sm outline-0",
          "focus:border-transparent focus:ring-primary",
          {
            "border-none  bg-[#F2F4F5] outline-gray-500":
              styleType === "shadow",
            "border-solid border-[#E3E5E5]": styleType === "outline",
          },
        ])}
        rows={4}
        {...props}
      />
      <div className="absolute bottom-2 right-0 text-xs leading-6 text-neutral-300">{`${props.value?.toString()?.length ?? 0}/${maxLength}文字`}</div>
    </div>
  );
};
