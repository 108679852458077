import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import type { UserCategory, UserInfo } from "@/types";

import type { RootState } from "../type";

export const UserSliceName = "user";

interface UserState extends UserInfo {
  user_type?: UserCategory;
}

const initialState: UserState = {
  user: {
    phone_number: "",
    nickname: "",
    company_name: "",
  },
};

const userSlice = createSlice({
  name: UserSliceName,
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateUser } = userSlice.actions;

export const useSelectUser = () => {
  return useSelector((state: RootState) => state[UserSliceName]);
};

export default userSlice.reducer;
