import { createBrowserRouter, Outlet } from "react-router-dom";

import {
  Apply,
  ApplyEntryConfirm,
  ApplyEntryDetail,
  ApplyEntryIndex,
  ApplyEntrySuccess,
  ApplymentDetail,
  Applyments,
  EntriesList,
  IdentityInfo,
  Login,
} from "@/pages";

import { ProtectedRoute } from "./ProtectedRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute redirectPath="/login">
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <EntriesList /> },
      { path: "entry", element: <EntriesList /> },

      { path: "apply", element: <Apply /> },
      {
        path: "apply/:category",
        element: <ApplyEntryDetail />,
        children: [
          { index: true, element: <ApplyEntryIndex /> },
          { path: "confirm", element: <ApplyEntryConfirm /> },
          { path: "success", element: <ApplyEntrySuccess /> },
        ],
      },
      {
        path: "application",
        element: <Applyments />,
      },
      { path: "application/:id", element: <ApplymentDetail /> },
      { path: "identity/:category", element: <IdentityInfo /> },
      { path: "login", element: <Login /> },
    ],
  },
]);

export default router;
