import { configureStore } from "@reduxjs/toolkit";

import bizKeyApplicationSlice, {
  BizKeyApplicationSliceName,
} from "./slices/bizkey";
import userSlice, { UserSliceName } from "./slices/user";

const store = configureStore({
  reducer: {
    [BizKeyApplicationSliceName]: bizKeyApplicationSlice,
    [UserSliceName]: userSlice,
  },
});

export default store;
