import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  BizKeyLOGO,
  Button,
  Card,
  Footer,
  Label,
  Main,
  Return,
} from "@/components";
import { useAuth } from "@/queries";
import { useUpdateCustomer } from "@/queries/mutation/useUpdateCustomer";
import { updateUser } from "@/redux/slices/user";
import { noti } from "@/shared/utils";

import { NameInputControl, PhoneInputControl } from "../components";

interface ResidentFormFields {
  name: string;
  phone: string;
}

export const ResidentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useAuth();
  const user = data?.data.user;
  const {
    control,
    handleSubmit,
    formState: { isValid, defaultValues },
  } = useForm<ResidentFormFields>({
    defaultValues: { name: user?.nickname, phone: user?.phone_number },
  });
  const { mutateAsync: saveUserInfo, isPending } = useUpdateCustomer();
  const handleSaveClick = async (values: ResidentFormFields) => {
    const { name, phone } = values;
    dispatch(
      updateUser({
        user: { nickname: name, phone_number: phone },
        user_type: "resident",
      })
    );
    await saveUserInfo()
      .then(() => {
        noti("保存しました。");
        if (!defaultValues?.name || !defaultValues?.phone) {
          navigate("/apply/resident", { replace: true });
        } else {
          navigate(-1);
        }
      })
      .catch((error) => {
        noti(error?.message || error.toString());
      });
  };
  return (
    <>
      <Main>
        <BizKeyLOGO />
        <Card>
          <Label htmlFor="" required>
            名前
          </Label>
          <NameInputControl id="name" name="name" control={control} />

          <Label htmlFor="" required>
            携帯番号
          </Label>
          <PhoneInputControl id="phone" name="phone" control={control} />

          <div className="mt-3">
            <Button
              disabled={!isValid}
              loading={isPending}
              onClick={handleSubmit(handleSaveClick)}
            >
              保存する
            </Button>
          </div>
        </Card>
        <Return className="mt-2" onClick={() => navigate(-1)} />
      </Main>
      <Footer />
    </>
  );
};
