import type { InputOption } from "./types";

export const weekdaysOptions: InputOption[] = [
  { value: 1, label: "月曜日" },
  { value: 2, label: "火曜日" },
  { value: 3, label: "水曜日" },
  { value: 4, label: "木曜日" },
  { value: 5, label: "金曜日" },
  { value: 6, label: "土曜日" },
  { value: 0, label: "日曜日" },
];

export const TOKYO_TIMEZONE = "Asia/Tokyo";
