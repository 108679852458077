import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { TOKYO_TIMEZONE } from "@/app.constant";
import { getWeekDaysOptionsRecord } from "@/shared/utils";
import type { BizKeyModal } from "@/types";

export function EntryTimeFormatter({
  application,
}: Readonly<{ application: BizKeyModal }>) {
  const { application_type, template } = application;
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { start_at, expired_at } = application.credential;
  const startDay = dayjs
    .utc(start_at)
    .tz(TOKYO_TIMEZONE)
    .format("YYYY年MM月DD日");
  const endDay = dayjs
    .utc(expired_at)
    .tz(TOKYO_TIMEZONE)
    .format("YYYY年MM月DD日");

  const { time_start, time_end, day_of_week, access_method, start_date } =
    template;
  const date = dayjs
    .utc(start_date)
    .tz(TOKYO_TIMEZONE)
    .format("YYYY年MM月DD日");
  const startTime = dayjs.utc(time_start).format("HH:mm");
  const endTime = dayjs.utc(time_end).format("HH:mm");

  const weekDaysRecord = getWeekDaysOptionsRecord();
  const weekDays = day_of_week?.map((day) => weekDaysRecord[day])?.join("、");

  if (application_type === "resident") {
    return `${startDay} - ${endDay}`;
  }
  if (access_method === "onetime") {
    return `${date} ${startTime} - ${endTime}`;
  } else {
    return (
      <>
        <p>{weekDays}</p>
        <p>{`${startTime} - ${endTime}`}</p>
      </>
    );
  }
}
