import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AUTH_KEY } from "@/queries";
import { useSelectUser } from "@/redux/slices/user";
import type { UpdateCustomerData } from "@/requests/customer";
import { updateCustomer } from "@/requests/customer";

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  const user = useSelectUser();
  const type = user.user_type;

  const mutationFn = () => {
    if (!type) throw new Error("please choose application type.");
    switch (type) {
      case "services":
        return mutationFnAgency();
      case "resident":
        return mutationFnResident();
    }
  };
  const mutationFnAgency = () => {
    const { company_name, nickname } = user.user;
    if (!company_name || !nickname) {
      throw new Error("information is not complete");
    }
    const data: UpdateCustomerData = {
      nickname,
      company_name,
    };
    return updateCustomer(data);
  };
  const mutationFnResident = () => {
    const { nickname, phone_number } = user.user;
    if (!nickname || !phone_number) {
      throw new Error("information is not complete");
    }
    const coutryCode = import.meta.env.VITE_COUNTRY_CODE;
    const data: UpdateCustomerData = {
      nickname,
      phone_number: coutryCode + phone_number,
    };
    return updateCustomer(data);
  };
  return useMutation({
    mutationFn,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
    },
  });
};
