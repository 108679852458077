import "./App.css";

import { cx } from "@emotion/css";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { queryClient } from "@/queries";
import { store } from "@/redux";
import router from "@/router";

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <div
          className={cx(
            "relative flex min-h-screen flex-col bg-neutral-20",
            "mx-auto w-full md:max-w-3xl"
          )}
          id="appRoot"
        >
          <RouterProvider router={router} />
        </div>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
