import { cx } from "@emotion/css";
import {
  Popup as NutPopup,
  type PopupProps as NuiPopupProps,
} from "@nutui/nutui-react";
import React from "react";
import ReactDOM from "react-dom";

interface PopupProps {
  visible: boolean;
  children?: React.ReactNode[];
  closeable?: boolean;
  className?: string;
}
export function Popup({
  visible,
  children,
  closeable = false,
  className,
  ...props
}: PopupProps & Partial<NuiPopupProps>) {
  return ReactDOM.createPortal(
    <NutPopup
      {...props}
      visible={visible}
      className={cx("w-2/3 min-w-24 max-w-72 rounded-md p-4 pt-6", className)}
      overlayStyle={{ backgroundColor: "rgba(0,0,0,0.4)" }}
      closeOnOverlayClick={false}
      style={{
        background: "linear-gradient(to bottom, #EDFDFF 0, #FFFFFF 50%)",
      }}
      closeIconPosition="top-right"
      closeable={closeable}
    >
      {children}
    </NutPopup>,
    document.body
  );
}

export function PopupHeader({
  children,
  className,
  // alert,
}: Readonly<{
  children: React.ReactNode;
  className?: string;
  // alert?: boolean;
}>) {
  return (
    <header
      className={cx(
        "align-middle text-sm font-semibold text-primary",
        className
      )}
    >
      {children}
    </header>
  );
}

export function PopupMain({
  children,
  className,
}: Readonly<{
  children: React.ReactNode;
  className?: string;
}>) {
  return (
    <main className={cx("my-2 text-sm leading-5 text-neutral-600", className)}>
      {children}
    </main>
  );
}

export function PopupAction({
  children,
  className,
}: Readonly<{ children: React.ReactNode; className?: string }>) {
  return <div className={cx("mt-5", className)}>{children}</div>;
}
