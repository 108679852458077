import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components";
import { clearBizKeyApplication } from "@/redux/slices/bizkey";

export default function ApplyEntrySuccessWrapper() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(clearBizKeyApplication());
  }, []);
  return (
    <div className="mt-auto">
      <Button onClick={() => navigate("/")}>トップページに戻る</Button>
    </div>
  );
}
