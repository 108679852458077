import { cx } from "@emotion/css";
import { Skeleton as NutSkeleton } from "@nutui/nutui-react";

interface SkeletonProps {
  rows?: number;
  className?: string;
}

export const Skeleton: React.FC<SkeletonProps> = ({ rows = 2, className }) => {
  return <NutSkeleton rows={rows} animated className={cx(className)} />;
};
