import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useSelectBizKeyApplication } from "@/redux/slices/bizkey";
import { addBizKeyApplication, checkApplicationOverLay } from "@/requests";

import { BIZKEY_KEY } from "..";
import { OverlapEntryTimeError } from "../errorTypes";

export const useCreateBizKeyOfResident = () => {
  const application = useSelectBizKeyApplication();
  const queryClient = useQueryClient();
  const mutationFn = async () => {
    const { application_type, property, room_number, comment } = application;
    if (!application_type || !property || !room_number) {
      throw new Error("information is not complete.");
    }

    const data = {
      property_id: property.value,
      application_type,
      room_number,
      comment: comment || null,
    };
    const isOverlap = await checkApplicationOverLay(data);
    if (isOverlap.data.is_overlapping) {
      throw new OverlapEntryTimeError(
        "時間帯が既存の申請と重なっているため、重複して申請を作成することはできません。"
      );
    }

    return addBizKeyApplication(data);
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BIZKEY_KEY, "awaiting"] });
    },
  });
};
