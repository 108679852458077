import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import { TextInput, type TextInputProps } from "@/components/form";

interface VerifyCodeInputControlProps extends TextInputProps {
  name: string;
  control: Control<any>;
}
export const VerifyCodeInputControl: React.FC<VerifyCodeInputControlProps> = ({
  name,
  control,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: {
      required: "確認コードは必須です",
      pattern: {
        value: /^[0-9]{6}$/,
        message: "確認コードが正しくありません",
      },
    },
  });
  return (
    <TextInput
      styleType="shadow"
      value={field.value}
      onChange={field.onChange}
      error={error?.message}
      {...props}
    />
  );
};
