import { Suspense } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BackToIndex, Button, Card, Label } from "@/components";
import { FormControl } from "@/components/form";
import {
  updateBizKeyApplication,
  useSelectBizKeyApplication,
} from "@/redux/slices/bizkey";
import type { AdmissionType, DayOfWeek, ValidDurationValue } from "@/types";

import {
  AdmissionTypeInputControl,
  AgencyApplyDetailLoading,
  CalendarInputControl,
  CommentInputControl,
  DurationInputControl,
  PropertyInputControl,
  TimeInputControl,
  WeekdayInputControl,
} from "../components";

interface AgentApplicationFormField {
  property: { value: string; label: string };
  comment: string;
  access_method: AdmissionType;
  start_date: string;
  valid_duration: {
    value: ValidDurationValue;
    label: string;
  };
  day_of_week: {
    value: DayOfWeek;
    label: string;
  }[];
  time: [string[], string[]];
}

export const AgentApplicationForm = () => {
  const applymentDetail = useSelectBizKeyApplication();
  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<AgentApplicationFormField>({
    defaultValues: applymentDetail,
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNextClick = (values: AgentApplicationFormField) => {
    const {
      property,
      access_method,
      start_date,
      time,
      comment,
      day_of_week,
      valid_duration,
    } = values;
    dispatch(
      updateBizKeyApplication({
        application_type: "services",
        property,
        access_method,
        start_date,
        time,
        comment,
        day_of_week,
        valid_duration,
      })
    );
    navigate("confirm");
  };

  return (
    <Suspense fallback={<AgencyApplyDetailLoading />}>
      <Card className="mt-2">
        <Label htmlFor="property" required>
          物件
        </Label>
        <PropertyInputControl
          id="property"
          name="property"
          placeholder="選択"
          control={control}
        />
        <Label htmlFor="access_method" required>
          入館日時
        </Label>
        <p className="text-xs leading-5 text-neutral-300">
          入館方法を選択してください：
        </p>
        <AdmissionTypeInputControl name="access_method" control={control} />
        {watch("access_method") === "onetime" && (
          <>
            <Label htmlFor="start_date" required>
              日付
            </Label>
            <CalendarInputControl
              id="start_date"
              name="start_date"
              control={control}
            />
            <Label htmlFor="time" required>
              時間
            </Label>
            <TimeInputControl id="time" name="time" control={control} />
          </>
        )}
        {watch("access_method") === "repeat" && (
          <>
            <Label htmlFor="day_of_week" required>
              曜日
            </Label>
            <WeekdayInputControl
              id="day_of_week"
              name="day_of_week"
              placeholder="選択"
              control={control}
            />
            <Label htmlFor="time" required>
              時間
            </Label>
            <TimeInputControl id="time" name="time" control={control} />
            <Label htmlFor="valid_duration" required>
              有効期間
            </Label>
            <DurationInputControl
              id="valid_duration"
              name="valid_duration"
              placeholder="選択"
              control={control}
            />
            <Label htmlFor="start_date" required>
              利用開始日
            </Label>
            <CalendarInputControl
              id="start_date"
              name="start_date"
              control={control}
            />
          </>
        )}

        <Label htmlFor="comment" className="!mb-0 font-semibold">
          コメント
        </Label>
        <p className="text-xs leading-6 text-neutral-300">
          何号室や理由などの追加情報があれば、こちらに記入して ください。
        </p>
        <CommentInputControl
          id="comment"
          name="comment"
          maxLength={200}
          control={control}
        />
        <FormControl className="">
          <Button disabled={!isValid} onClick={handleSubmit(handleNextClick)}>
            次へ
          </Button>
          <BackToIndex className="mb-1 mt-4 text-center" />
        </FormControl>
      </Card>
    </Suspense>
  );
};
