import * as OTPAuth from "otpauth";
import { useEffect, useState } from "react";

import { useGetBizKeyById } from "@/queries";
import { encodeBase32 } from "@/shared/utils";

export function useGenerateTotp(
  selectedApplicationId: string | undefined,
  isExpired: boolean
) {
  const [otp, setOtp] = useState("");
  const { data, isLoading, isSuccess } = useGetBizKeyById(
    selectedApplicationId
  );
  const application = data?.data;

  async function freshTotp() {
    if (isSuccess && !isExpired) {
      try {
        const encodedSecret = encodeBase32(application?.credential.secret);
        const totp = new OTPAuth.TOTP({
          algorithm: "SHA1",
          digits: 6,
          period: 60,
          secret: OTPAuth.Secret.fromBase32(encodedSecret), // or ''
        });
        const token = totp.generate();
        setOtp(token);
      } catch (e) {
        console.error(e);
      }
    }
  }

  function timeRemaining() {
    return 60 - (Math.floor(Date.now() / 1000) % 60);
  }

  useEffect(() => {
    isSuccess && !isExpired && freshTotp();
  }, [isSuccess, isExpired]);

  return {
    otp,
    freshTotp,
    isLoading,
    isSuccess,
    application,
    timeRemaining,
  };
}
