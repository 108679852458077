import { cx } from "@emotion/css";
import type { FunctionComponent } from "react";

interface CodeBoardProps {
  className?: string;
  children: string;
}

export const CodeBoard: FunctionComponent<CodeBoardProps> = ({
  className,
  children,
}) => {
  return (
    <div className={cx("bg-neutral-30 rounded-md p-2", className)}>
      <p className="mx-auto text-center text-xl text-primary">{children}</p>
    </div>
  );
};
