import { useMutation } from "@tanstack/react-query";

import { createUser, logout, sendCode } from "@/requests";

export const usePreLogin = () => {
  return useMutation({ mutationFn });
};
const mutationFn = async ({ username }: { username: string }) => {
  try {
    await logout();
    await createUser({ email: username });
    return sendCode({ username });
  } catch (error) {
    if ((error as { status?: number })?.status === 400) {
      return sendCode({ username });
    }
    return Promise.reject(error);
  }
};
