import { cx } from "@emotion/css";
import { CountDown as NutCountDown } from "@nutui/nutui-react";
import { useRef } from "react";

interface CountdownProps {
  duration: number;
  onCoutdownEnd?: () => void;
  className?: string;
}

export const Countdown: React.FC<CountdownProps> = ({
  duration,
  onCoutdownEnd,
  className,
}) => {
  const stateRef = useRef({
    remainingTime: duration * 1000,
  });
  return (
    <NutCountDown
      remainingTime={stateRef.current.remainingTime}
      onEnd={onCoutdownEnd}
      format="ss"
      className={cx(className)}
    />
  );
};
