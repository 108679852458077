import { type Control, Controller } from "react-hook-form";

import type { StaticSelectInputProps } from "@/components/form";
import { StaticSelectInput } from "@/components/form";
import type { InputOption } from "@/types";

interface DurationInputProps
  extends Pick<StaticSelectInputProps, "id" | "placeholder"> {
  name: string;
  control: Control<any>;
}

export const DurationInputControl: React.FC<DurationInputProps> = ({
  name,
  control,
  ...props
}) => {
  const options: InputOption[] = [
    {
      value: 90,
      label: "3ヶ月 ",
    },
    {
      value: 180,
      label: "6ヶ月",
    },
  ];

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => {
        const handleOptionClick = (e: React.MouseEvent<HTMLDivElement>) => {
          const optionEle = e.target as HTMLParagraphElement;
          if (optionEle.dataset.optionValue) {
            onChange({
              value: parseInt(optionEle.dataset.optionValue),
              label: optionEle.textContent,
            });
          }
        };
        return (
          <StaticSelectInput
            value={value?.label}
            options={options}
            handleOptionClick={handleOptionClick}
            {...props}
          />
        );
      }}
    />
  );
};
