import { cx } from "@emotion/css";

import ArrowIcon from "@/assets/return-down.svg?react";
import { useModal } from "@/hooks";
import type { InputOption } from "@/types";

import { InfiniteLoadingBoard } from "..";
import type { InputProps } from "./Input";
import Input from "./Input";
import { Popup } from "./Popup";

export interface SelectInputProps extends InputProps {
  options: InputOption[];
  hasNextPage: boolean;
  fetchNextPage: any;
  // eslint-disable-next-line no-unused-vars
  handleOptionClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  optionsLoading?: boolean;
}

export const SelectInput = ({
  options,
  hasNextPage,
  fetchNextPage,
  handleOptionClick,
  placeholder,
  optionsLoading,
  ...props
}: SelectInputProps) => {
  const { visible, toggle } = useModal();
  const handleLoadMore = async () => {
    await fetchNextPage();
  };
  return (
    <div className="relative">
      <ArrowIcon className="absolute right-3 top-0 z-20 my-4 h-4 w-4" />
      <Input readOnly placeholder={placeholder} onClick={toggle} {...props} />
      <Popup
        visible={visible}
        onClick={toggle}
        onClose={() => {
          visible && toggle();
        }}
      >
        <p className="p-5 pb-0 text-center text-base">{placeholder}</p>
        <div
          id="infinite-load-target"
          className={cx(
            "divide-x-0 divide-y divide-solid divide-[#E9EDEE]",
            "h-[500px] overflow-auto p-4"
          )}
          onClick={handleOptionClick}
        >
          <InfiniteLoadingBoard
            target="infinite-load-target"
            onLoadMore={handleLoadMore}
            hasMore={hasNextPage}
            loading={optionsLoading}
          >
            {options?.map((optionValue, index) => (
              <p
                data-option-value={optionValue.value}
                className="text-text-gray text-center text-base leading-12"
                key={index}
              >
                {optionValue.label}
              </p>
            ))}
          </InfiniteLoadingBoard>
        </div>
      </Popup>
    </div>
  );
};
