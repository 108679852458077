import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

import ReturnIcon from "@/assets/return-index.svg?react";

interface BackIndexProps {
  className?: string;
}
export const BackToIndex: React.FC<BackIndexProps> = ({ className }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate("/")}
      className={cx("mx-auto block text-sm text-primary underline", className)}
    >
      <ReturnIcon className={cx("mb-0.5 mr-1 inline h-4 w-4")} />
      トップページに戻る
    </button>
  );
};
