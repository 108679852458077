import { type Control, useController } from "react-hook-form";

import type { TextInputProps } from "@/components/form";
import { TextInput } from "@/components/form";

interface UserNameInputControlProps extends TextInputProps {
  name: string;
  control: Control<any>;
}
export const UserNameInputControl: React.FC<UserNameInputControlProps> = ({
  name,
  control,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: {
      required: "メールアドレスは必須です",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: "形式が正しくありません",
      },
    },
  });
  return (
    <TextInput
      value={field.value}
      onChange={field.onChange}
      styleType="shadow"
      error={error?.message}
      {...props}
    />
  );
};
