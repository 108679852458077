import { useLocation, useNavigate } from "react-router-dom";

import { Footer, Header, Main, TabPane, Tabs } from "@/components";
import type { ApplicationStatus } from "@/types";

import { InfiniteApplications } from "./components";

export default function Applyments() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const status = query.get("status") as ApplicationStatus;

  const handleDetailClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const buttonEle = e.target;
    if (buttonEle instanceof HTMLButtonElement && buttonEle.dataset.bizkeyId) {
      navigate(`/application/${buttonEle.dataset.bizkeyId}`);
    }
  };

  return (
    <>
      <Header canBack>申請一覧</Header>
      <Main className="!m-0">
        <Tabs
          value={status}
          onChange={(value) => {
            navigate("/application?status=" + value, { replace: true });
          }}
        >
          <TabPane value="awaiting" title="承認待ち">
            <div onClick={handleDetailClick}>
              <InfiniteApplications
                status="awaiting"
                actived={status === "awaiting"}
              />
            </div>
          </TabPane>
          <TabPane value="approved" title="承認した">
            <div onClick={handleDetailClick}>
              <InfiniteApplications
                status="approved"
                actived={status === "approved"}
              />
            </div>
          </TabPane>
          <TabPane value="rejected" title="不承認">
            <div onClick={handleDetailClick}>
              <InfiniteApplications
                status="rejected"
                actived={status === "rejected"}
              />
            </div>
          </TabPane>
          <TabPane value="expired" title="期限切れ">
            <div onClick={handleDetailClick}>
              <InfiniteApplications
                status="expired"
                actived={status === "expired"}
              />
            </div>
          </TabPane>
        </Tabs>
      </Main>
      <Footer />
    </>
  );
}
