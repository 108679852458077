import type { TabPaneProps as NutTabPaneProps } from "@nutui/nutui-react";
import { TabPane as NutTabPane } from "@nutui/nutui-react";

interface TabPaneProps extends Pick<NutTabPaneProps, "value" | "title"> {
  children: React.ReactNode;
}
export const TabPane: React.FC<TabPaneProps> = ({ children, ...props }) => {
  return (
    <NutTabPane className="!bg-neutral-20 !px-4 !pt-0" {...props}>
      {children}
    </NutTabPane>
  );
};
