import { useState } from "react";

import { UserName, VerifyCode } from "./components";
import { LoginContext, useLoginContext } from "./useLoginContext";

export default function LoginWrapper() {
  const isSendCodeProvider = useState(false);
  return (
    <LoginContext.Provider value={{ isSendCodeProvider }}>
      <LoginContainer />
    </LoginContext.Provider>
  );
}

export const LoginContainer = () => {
  const { isSendCodeProvider } = useLoginContext();
  const [isSendCode] = isSendCodeProvider;
  return <>{isSendCode ? <VerifyCode /> : <UserName />}</>;
};
