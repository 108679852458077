import React from "react";
import { type Control, Controller } from "react-hook-form";

import type { TimeInputProps } from "@/components/form";
import { TimeInput } from "@/components/form";
import { getDeviceType } from "@/shared/utils";
const LazyWebDatePicker = React.lazy(
  () => import("@/components/date-picker/WebDatePicker")
);

interface TimeInputControlProps extends Pick<TimeInputProps, "id"> {
  name: string;
  control: Control<any>;
}
export const TimeInputControl: React.FC<TimeInputControlProps> = ({
  name,
  control,
}) => {
  const userAgent = getDeviceType();
  switch (userAgent) {
    case "mobile":
      return <MobileTimeInputControl name={name} control={control} />;
    case "tablet":
    case "desktop":
      return <GeneralTimeInputControl name={name} control={control} />;
  }
};

const GeneralTimeInputControl: React.FC<TimeInputControlProps> = ({
  name,
  control,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
        validate: {
          notEmpty: (value) => {
            if (value[0]?.length === 0 || value[1]?.length === 0) {
              return false;
            }
          },
          lessThen12Hours: (value) => {
            const [start, end] = value;
            if (start && end) {
              const startTime = new Date(`2021-01-01 ${start?.join(":")}`);
              const endTime = new Date(`2021-01-01 ${end?.join(":")}`);
              const diff = endTime.getTime() - startTime.getTime();
              if (diff < 0 || diff > 12 * 60 * 60 * 1000) {
                return "最大12時間です";
              }
            }
          },
        },
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const handleTimeInputChange = (dayStrings: [string, string]) => {
          const [startValue, endValue] = dayStrings;
          let newValue: [][] | string[][] = [[], []];
          if (startValue && endValue) {
            newValue = [startValue?.split(":"), endValue?.split(":")];
          }
          onChange(newValue);
        };
        return (
          <LazyWebDatePicker
            defaultValue={value}
            onChange={handleTimeInputChange}
            error={error?.message}
          />
        );
      }}
    />
  );
};

const MobileTimeInputControl: React.FC<TimeInputControlProps> = ({
  name,
  control,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
        validate: {
          notEmpty: (value) => {
            if (value[0]?.length === 0 || value[1]?.length === 0) {
              return false;
            }
          },
          lessThen12Hours: (value) => {
            const [start, end] = value;
            if (start && end) {
              const startTime = new Date(`2021-01-01 ${start?.join(":")}`);
              const endTime = new Date(`2021-01-01 ${end?.join(":")}`);
              const diff = endTime.getTime() - startTime.getTime();
              if (diff < 0 || diff > 12 * 60 * 60 * 1000) {
                return "最大12時間です";
              }
            }
          },
        },
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const handleStartConfirmClick = (values: (string | number)[]) => {
          const startTime = values;
          onChange([startTime, value[1]]);
        };

        const handleEndConfirmClick = (values: (string | number)[]) => {
          const endTime = values;
          onChange([value[0], endTime]);
        };
        return (
          <TimeInput
            defaultStartTime={value[0]}
            defaultEndTime={value[1]}
            handleStartConfirmClick={handleStartConfirmClick}
            handleEndConfirmClick={handleEndConfirmClick}
            error={error?.message}
          />
        );
      }}
    />
  );
};
