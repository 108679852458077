import { cx } from "@emotion/css";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { memo } from "react";

import { TOKYO_TIMEZONE } from "@/app.constant";
import TriangleIcon from "@/assets/triangle.svg?react";
import {
  ApplyInfoCard,
  ApplyInfoCardBody,
  ApplyInfoCardHeader,
  InfiniteLoadingBoard,
} from "@/components";
import { useInfiniteListBizKey } from "@/queries";
import type { ApplicationStatus } from "@/types";

interface InfiniteApplicationsProps {
  status: ApplicationStatus;
  actived?: boolean;
}

export const InfiniteApplications: React.FC<InfiniteApplicationsProps> = memo(
  function ({ status, actived }) {
    const { data, hasNextPage, fetchNextPage, isLoading } =
      useInfiniteListBizKey(status, actived);
    const handleLoadMore = async () => {
      await fetchNextPage();
    };
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return (
      <InfiniteLoadingBoard
        onLoadMore={handleLoadMore}
        hasMore={hasNextPage}
        loading={isLoading}
      >
        {data?.pages?.flatMap((page) =>
          page.data.applications?.map((bizKey) => (
            <ApplyInfoCard className="relative mt-4" key={bizKey.id}>
              <TriangleIcon
                className={cx(
                  "absolute bottom-0 right-0",
                  status == "approved" && "text-[#52C41A]",
                  status == "rejected" && "text-[#F51E1E]",
                  status == "awaiting" && "text-[#E9EDEE]",
                  status == "expired" && "text-[#909899]"
                )}
              />
              <ApplyInfoCardHeader className="flex justify-between">
                <p className="text-sm font-medium">{bizKey.property.name}</p>
                <button
                  className="z-30 text-xs text-primary"
                  data-bizkey-id={bizKey.id}
                >
                  詳細
                </button>
              </ApplyInfoCardHeader>
              <ApplyInfoCardBody>
                <section>
                  <p className="text-xxs text-neutral-100">作成日時</p>
                  <p className="text-xs">
                    {`${dayjs.utc(bizKey.create_time).tz(TOKYO_TIMEZONE).format("YYYY年MM月DD日 HH:mm:ss")}`}
                  </p>
                </section>
              </ApplyInfoCardBody>
            </ApplyInfoCard>
          ))
        )}
      </InfiniteLoadingBoard>
    );
  }
);
