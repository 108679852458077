import { Toast, type ToastProps } from "@nutui/nutui-react";
import type { CSSProperties } from "react";

export function noti(msg: string, options?: Omit<ToastProps, "content">) {
  Toast.show({
    content: msg,
    style: {
      "--nutui-toast-inner-top": "10%",
    } as CSSProperties,
    contentStyle: {
      width: "100%",
    },
    ...options,
  });
}
