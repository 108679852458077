import type { AxiosResponse } from "axios";

import { server } from "@/requests";
import type {
  AdmissionType,
  ApplicationStatus,
  BizKeyModal,
  UserCategory,
} from "@/types";

export interface GETBizKeysResponse {
  applications: BizKeyModal[];
  total: number;
  total_pages: number;
}

export interface GETBizKeysParams {
  status: ApplicationStatus;
  is_entry_list?: boolean;
  page?: number;
  page_size?: number;
}

export const getBizKeys = async (params: GETBizKeysParams) => {
  return server.get<unknown, AxiosResponse<GETBizKeysResponse>>(
    "/services/bizkey/applications",
    { params }
  );
};

export const getBizKeyById = async (application_id: string) => {
  return server.get<unknown, AxiosResponse<BizKeyModal>>(
    `/services/bizkey/applications/${application_id}`
  );
};

interface CreateBizKeyApplicationData {
  property_id: string;
  application_type: UserCategory;
  room_number?: string;
  comment: string | null;
  date?: string;
  start_time?: string;
  end_time?: string;
  start_date?: string;
  access_method?: AdmissionType;
  time_start?: string;
  time_end?: string;
  day_of_week?: number[];
  valid_duration?: number;
}

export const addBizKeyApplication = async ({
  property_id,
  ...data
}: CreateBizKeyApplicationData) => {
  return server.post(
    `/properties/${property_id}/services/bizkey/applications`,
    data
  );
};

export const checkApplicationOverLay = async ({
  property_id,
  ...data
}: CreateBizKeyApplicationData) => {
  return server.post<unknown, AxiosResponse<{ is_overlapping: boolean }>>(
    `/properties/${property_id}/services/bizkey/applications/check_overlap`,
    data
  );
};
