import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PageNotFound } from "@/components";
import { useGetUserCategory } from "@/hooks";
import { useAuth } from "@/queries";
import { noti } from "@/shared/utils";

import { AgentApplicationForm } from "./AgentApplicationForm";
import { ResidentApplicationForm } from "./ResidentApplicationForm";

export default function EntryApplyIndexWrapper() {
  const category = useGetUserCategory();
  const navigate = useNavigate();
  const {
    data: userData,
    isSuccess: isAuthSucceed,
    isError: isAuthError,
  } = useAuth();
  useEffect(() => {
    if (isAuthSucceed) {
      const userInfo = userData.data.user;
      if (
        category === "services" &&
        (!userInfo.company_name || !userInfo.nickname)
      ) {
        navigate(`/identity/${category}`, { replace: true });
      }
      if (
        category === "resident" &&
        (!userInfo.nickname || !userInfo.phone_number)
      ) {
        navigate(`/identity/${category}`, { replace: true });
      }
    }
  }, [isAuthSucceed]);

  if (isAuthError) {
    noti("ユーザー情報の取得に失敗しました。");
    navigate(-1);
  }
  switch (category) {
    case "services":
      return <AgentApplicationForm />;
    case "resident":
      return <ResidentApplicationForm />;
    default:
      return <PageNotFound />;
  }
}
