export const removeUnexpectedChar = (
  text: string | number,
  regexp: RegExp
): string | number => {
  if (typeof text === "string") {
    return text.replace(regexp, "");
  } else {
    return parseInt(text.toString().replace(regexp, ""));
  }
};

export const NotSpecialCharRegExp = new RegExp(/\W/, "g");
export const OnlyNumberRegExp = new RegExp(/\D/, "g");
