import type { InfiniteLoadingProps } from "@nutui/nutui-react";
import { InfiniteLoading as NutInfiniteLoading } from "@nutui/nutui-react";

import { Loading } from "..";

interface InfiniteLoadingBoardProps
  extends Pick<InfiniteLoadingProps, "onLoadMore" | "hasMore"> {
  children?: React.ReactNode | React.ReactNode[];
  loadMoreText?: string;
  loadingText?: string;
  loading?: boolean;
  target?: InfiniteLoadingProps["target"];
}

export const InfiniteLoadingBoard: React.FC<InfiniteLoadingBoardProps> = ({
  children,
  loadingText = "Loading...",
  loadMoreText = <></>,
  loading,
  ...props
}) => {
  return (
    <NutInfiniteLoading
      loadMoreText={loadMoreText}
      loadingText={loadingText}
      {...props}
    >
      {children}
      {loading && <Loading className="mt-2" />}
    </NutInfiniteLoading>
  );
};
