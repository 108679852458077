import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { TOKYO_TIMEZONE } from "@/app.constant";
import { useSelectBizKeyApplication } from "@/redux/slices/bizkey";
import { addBizKeyApplication, checkApplicationOverLay } from "@/requests";

import { BIZKEY_KEY } from "..";
import { OverlapEntryTimeError } from "../errorTypes";

export const useCreateBizKeyOfServices = () => {
  const application = useSelectBizKeyApplication();
  const queryClient = useQueryClient();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const mutationFn = async () => {
    const { access_method, application_type } = application;
    if (!access_method || !application_type) {
      throw new Error("access method is required.");
    }

    if (access_method === "onetime") {
      const { start_date, comment, property, time } = application;
      if (!start_date || !property || !time) {
        throw new Error("information of one time application not complete.");
      }

      const data = {
        property_id: property?.value,
        application_type,
        access_method,
        time_start: `0001-01-01T${time[0].join(":")}:00Z`,
        time_end: `0001-01-01T${time[1].join(":")}:00Z`,
        start_date: dayjs.utc(start_date).tz(TOKYO_TIMEZONE, true).format(),
        comment: comment || null,
      };
      const isOverlap = await checkApplicationOverLay(data);
      if (isOverlap.data.is_overlapping) {
        throw new OverlapEntryTimeError(
          "時間帯が既存の申請と重なっているため、重複して申請を作成することはできません。"
        );
      }
      return addBizKeyApplication(data);
    } else {
      const {
        property,
        start_date,
        day_of_week,
        valid_duration,
        comment,
        time,
      } = application;
      if (
        !property ||
        !start_date ||
        !day_of_week?.length ||
        !valid_duration?.value ||
        !time
      )
        throw new Error("information of repeat application not complete.");
      const data = {
        property_id: property?.value,
        application_type,
        access_method,
        time_start: `0001-01-01T${time[0].join(":")}:00Z`,
        time_end: `0001-01-01T${time[1].join(":")}:00Z`,
        start_date: dayjs.utc(start_date).tz(TOKYO_TIMEZONE, true).format(),
        day_of_week: day_of_week.map((item) => item.value),
        valid_duration: valid_duration.value,
        comment: comment || null,
      };
      const isOverlap = await checkApplicationOverLay(data);
      if (isOverlap.data.is_overlapping) {
        throw new OverlapEntryTimeError(
          "時間帯が既存の申請と重なっているため、重複して申請を作成することはできません。"
        );
      }
      return addBizKeyApplication(data);
    }
  };
  return useMutation({
    mutationFn,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [BIZKEY_KEY, "awaiting"] }),
  });
};
