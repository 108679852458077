import { cx } from "@emotion/css";
import { useParams } from "react-router-dom";

import {
  ApplyInfoCard,
  ApplyInfoCardBody,
  ApplyInfoCardBodyItem,
  ApplyInfoCardHeader,
  Card,
  Footer,
  Header,
  Main,
  Skeleton,
  UserInfoCard,
} from "@/components";
import { useAuth, useGetBizKeyById } from "@/queries";
import type { ApplicationStatus } from "@/types";

import { EntryTimeFormatter } from "./components";

export default function ApplymentDetail() {
  const { id } = useParams() as { id: string };
  const { data, isLoading } = useGetBizKeyById(id);
  const { data: userData } = useAuth();
  const user = userData?.data;
  const application = data?.data;
  if (isLoading)
    return (
      <>
        <Header canBack>申請詳細</Header>
        <Main>
          <Skeleton className="mt-4" />
        </Main>
      </>
    );
  return (
    <>
      <Header canBack>申請詳細</Header>
      <Main>
        <ApplyInfoCard className="mt-4">
          <ApplyInfoCardHeader className="text-sm font-medium">
            申請内容
          </ApplyInfoCardHeader>
          <ApplyInfoCardBody>
            <ApplyInfoCardBodyItem title="物　　件：">
              <span>{application?.property.name}</span>
            </ApplyInfoCardBodyItem>

            {application?.application_type === "services" ? (
              <ApplyInfoCardBodyItem title="入館日時：">
                <EntryTimeFormatter application={application} />
              </ApplyInfoCardBodyItem>
            ) : (
              <ApplyInfoCardBodyItem title="部屋番号：">
                <span>{application?.template.room_number}</span>
              </ApplyInfoCardBodyItem>
            )}

            <ApplyInfoCardBodyItem title="コメント：">
              {application?.comment ? (
                <p>{application.comment}</p>
              ) : (
                <p className="text-neutral-100">無し</p>
              )}
            </ApplyInfoCardBodyItem>
          </ApplyInfoCardBody>
        </ApplyInfoCard>
        <UserInfoCard user={user} type={application?.application_type} />
        <StatusCaption
          status={application?.status}
          comment={application?.review_comment}
        />
      </Main>
      <Footer />
    </>
  );
}
interface StatusCaptionProps {
  status: ApplicationStatus | undefined;
  comment?: string;
}

const StatusCaption: React.FC<StatusCaptionProps> = ({ status, comment }) => {
  if (!status) return null;
  switch (status) {
    case "awaiting":
      return (
        <Card className="mt-4">
          <StatusCaptionHeader className="border-[#D9D9D9]">
            承認待ち
          </StatusCaptionHeader>
        </Card>
      );
    case "approved":
      return (
        <Card className="mt-4">
          <StatusCaptionHeader className="border-[#52C41A]">
            承認されました
          </StatusCaptionHeader>
          <Comment>{comment}</Comment>
        </Card>
      );
    case "rejected":
      return (
        <Card className="mt-4">
          <StatusCaptionHeader className="border-[#F51E1E]">
            拒否されました
          </StatusCaptionHeader>
          <Comment>{comment}</Comment>
        </Card>
      );
    case "expired":
      return (
        <Card className="mt-4">
          <StatusCaptionHeader className="border-[#909899]">
            承認期限が切れました
          </StatusCaptionHeader>
        </Card>
      );
  }
};

const StatusCaptionHeader = ({
  children,
  className,
}: {
  children: string;
  className?: string;
}) => {
  return (
    <header
      className={cx(
        "leading-sm pl-2 text-sm font-medium leading-3.5",
        "border-l-[3px] border-solid",
        className
      )}
    >
      {children}
    </header>
  );
};

const Comment = ({ children }: { children: string | undefined }) => {
  return (
    <section className="mb-2 mt-4 flex gap-2 text-xs text-neutral-100">
      <span>コメント：</span>
      {children ? (
        <article className="text-neutral-900">{children}</article>
      ) : (
        <article>無し</article>
      )}
    </section>
  );
};
