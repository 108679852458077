import { Calendar, ConfigProvider } from "@nutui/nutui-react";

import CalendarIcon from "@/assets/calendar.svg?react";
import { useModal } from "@/hooks";
import jp from "@/locales/ja-JP.js";
import { tomorrow } from "@/shared/utils";

import type { InputProps } from "./Input";
import Input from "./Input";

export interface CalendarInputProps extends InputProps {
  duration?: number;
  // eslint-disable-next-line no-unused-vars
  handleConfirmClick: (param: string) => void; // 点击日历上确认按钮的回调
}
export const CalendarInput: React.FC<CalendarInputProps> = ({
  duration = 30,
  handleConfirmClick,
  // defaultValue,
  ...props
}) => {
  const { visible, toggle } = useModal();

  const today = new Date();
  const theEndDay = new Date();
  theEndDay.setDate(today.getDate() + duration);
  const todayString = today.toISOString().split("T")[0];
  const tomorrowString = tomorrow();
  const theEndDayString = theEndDay.toISOString().split("T")[0];

  return (
    <div className="relative">
      <CalendarIcon className="absolute right-3 top-0 z-20 my-4 h-4 w-4" />
      <Input onClick={toggle} placeholder="選択" readOnly {...props} />
      <ConfigProvider locale={jp}>
        <Calendar
          visible={visible}
          defaultValue={(props.value as string) || tomorrowString}
          startDate={todayString}
          endDate={theEndDayString}
          onClose={toggle}
          onConfirm={(param: string) => handleConfirmClick(param)}
          title="日付を選択"
          confirmText="確認"
          showSubTitle={false}
          showToday={false}
        />
      </ConfigProvider>
    </div>
  );
};
