import * as base32 from "hi-base32";

import { weekdaysOptions } from "@/app.constant";
import type { InputOption } from "@/types";

export function getDeviceType(): "mobile" | "tablet" | "desktop" {
  const userAgent = navigator.userAgent;
  if (/mobile/i.test(userAgent)) {
    return "mobile";
  } else if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
    return "tablet";
  } else {
    return "desktop";
  }
}

export const encodeBase32 = (str: string | undefined): string => {
  if (!str) throw new Error("Secret is required");
  return base32.encode(str);
};

export function getWeekDaysOptionsRecord() {
  return weekdaysOptions.reduce(
    (record: Record<number, string>, item: InputOption) => {
      record[item.value] = item.label;
      return record;
    },
    {}
  );
}
