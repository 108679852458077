import { type Control, useController } from "react-hook-form";

import type { TextInputProps } from "@/components/form";
import { TextInput } from "@/components/form";

interface NameInputControlProps extends TextInputProps {
  name: string;
  control: Control<any>;
}
export const NameInputControl: React.FC<NameInputControlProps> = ({
  name,
  control,
  ...props
}) => {
  const {
    field: { value, onChange },
  } = useController({ name, control, rules: { required: true } });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cut = e.target.value?.slice(0, 50);
    onChange(cut);
  };

  return <TextInput value={value} {...props} onChange={handleChange} />;
};
