import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BackToIndex, Button, Card, Label } from "@/components";
import {
  updateBizKeyApplication,
  useSelectBizKeyApplication,
} from "@/redux/slices/bizkey";

import {
  CommentInputControl,
  PropertyInputControl,
  RoomInputControl,
} from "../components";

interface ResidentApplicationFormField {
  property: { value: string; label: string };
  room_number: string;
  comment: string;
}

export const ResidentApplicationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const application = useSelectBizKeyApplication();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ResidentApplicationFormField>({
    defaultValues: application,
    mode: "onChange",
  });
  const handleNextClick = (values: ResidentApplicationFormField) => {
    const { property, room_number, comment } = values;
    dispatch(
      updateBizKeyApplication({
        property,
        room_number: room_number.trim(),
        comment,
        application_type: "resident",
      })
    );
    navigate("confirm");
  };
  return (
    <Card className="mt-4">
      <Label htmlFor="property" required>
        物件
      </Label>
      <PropertyInputControl
        id="property"
        name="property"
        placeholder="選択"
        control={control}
      />
      <Label htmlFor="" required>
        部屋番号
      </Label>
      <RoomInputControl id="room_number" name="room_number" control={control} />
      <Label htmlFor="comment" className="!mb-0 font-semibold">
        コメント
      </Label>
      <p className="mb-2 text-xs leading-6 text-neutral-300">
        何号室や理由などの追加情報があれば、こちらに記入して ください。
      </p>
      <CommentInputControl
        id="comment"
        name="comment"
        maxLength={200}
        control={control}
      />
      <div className="mt-3">
        <Button disabled={!isValid} onClick={handleSubmit(handleNextClick)}>
          次へ
        </Button>
        <BackToIndex className="mb-1 mt-4 text-center" />
      </div>
    </Card>
  );
};
