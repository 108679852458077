import { cx } from "@emotion/css";

import { Card } from "@/components";

interface ApplyInfoCardProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}
export const ApplyInfoCard: React.FC<ApplyInfoCardProps> = ({
  className,
  children,
}) => {
  return <Card className={cx("", className)}>{children}</Card>;
};

interface ApplyInfoCardHeaderProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const ApplyInfoCardHeader: React.FC<ApplyInfoCardHeaderProps> = ({
  className,
  children,
}) => {
  return <div className={cx("", className)}>{children}</div>;
};

interface ApplyInfoCardBodyProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const ApplyInfoCardBody: React.FC<ApplyInfoCardBodyProps> = ({
  children,
  className,
}) => {
  return (
    <div className={cx("mt-4 flex flex-col gap-2 text-xs", className)}>
      {children}
    </div>
  );
};

interface ApplyInfoCardBodyItemProps {
  title: string;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const ApplyInfoCardBodyItem: React.FC<ApplyInfoCardBodyItemProps> = ({
  title,
  className,
  children,
}) => {
  return (
    <div className={cx("flex", className)}>
      <aside className="shrink-0 basis-16">{title}</aside>
      <main className="">{children}</main>
    </div>
  );
};
