import { Outlet, useLocation } from "react-router-dom";

import { BizKeyLOGO, Card, Footer, Main } from "@/components";

import { Dashline, StepIndicator } from "../components";
import { calRouteLight } from "../utils";

export const AgencyApplyDetail = () => {
  const location = useLocation().pathname;
  const isEnd = location.includes("success");
  return (
    <>
      <Main className="flex flex-col">
        <BizKeyLOGO />
        <Card className="text-sm">
          {isEnd ? (
            <p className="text-center text-sm font-semibold leading-9">
              申請を提出しました
            </p>
          ) : (
            <p>
              {
                "サービス事業者の方は、マンションのエントランス解錠許可を物件管理者に申請できます。"
              }
            </p>
          )}
          <div className="mx-8 mt-5 flex justify-between">
            <StepIndicator
              sequence={1}
              caption="入力"
              active={calRouteLight(location, 1)}
            />
            <Dashline active={calRouteLight(location, 2)} />
            <StepIndicator
              sequence={2}
              caption="確認"
              active={calRouteLight(location, 2)}
            />
            <Dashline active={calRouteLight(location, 3)} />
            <StepIndicator
              sequence={3}
              caption="完了"
              active={calRouteLight(location, 3)}
            />
          </div>
          {isEnd && (
            <p className="mt-5 text-xs">
              入館申請の結果は、物件管理者の審査後、ご記入いただい
              たメールアドレスに送信されます。
            </p>
          )}
        </Card>
        <Outlet />
      </Main>
      <Footer className="mt-3" />
    </>
  );
};
