import { useInfiniteQuery } from "@tanstack/react-query";

import { PROPERTY_KEY } from "@/queries";
import type { GetPropertiesParams } from "@/requests";
import { getProperties } from "@/requests";

export function useInfiniteListProperty(active_service = "bizKey") {
  const queryFn = async (params: GetPropertiesParams) => {
    return getProperties(params);
  };

  const initialPageParam: GetPropertiesParams = {
    active_service,
    page: 1,
    page_size: 10,
  };
  return useInfiniteQuery({
    queryKey: [PROPERTY_KEY, initialPageParam],
    queryFn: ({ pageParam }) => queryFn(pageParam),
    initialPageParam,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (allPages.length >= lastPage.data.total_pages) return undefined;
      return {
        ...lastPageParam,
        page: allPages.length + 1,
      };
    },
  });
}
