import { createContext, useContext } from "react";

import type { UseState } from "@/types";

interface LoginContextType {
  isSendCodeProvider: UseState<boolean>;
}

export const LoginContext = createContext<LoginContextType | null>(null);

export const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error("useLoginContext must be used within a LoginProvider");
  }
  return context;
};
