import { AuthError } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { BizKeyLOGO, Button, Footer, Label, Return } from "@/components";
import { FormControl } from "@/components/form";
import { useSignIn } from "@/queries/mutation/useSignIn";
import { noti } from "@/shared/utils";

import { useLoginContext } from "../useLoginContext";
import { VerifyCodeInputControl } from ".";

interface VerifyCodeForm {
  code: string;
}

export const VerifyCode = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<VerifyCodeForm>({
    mode: "onChange",
  });

  const { isSendCodeProvider } = useLoginContext();
  const [, setIsSendCode] = isSendCodeProvider;

  const { mutateAsync: signIn, isPending } = useSignIn();

  const handleLoginClick = async (values: VerifyCodeForm) => {
    const { code } = values;
    await signIn({ code })
      .then((response) => {
        if (response.isSignedIn) {
          navigate("/", { replace: true });
        } else {
          noti("確認コードが正しくありません");
        }
      })
      .catch((error) => {
        if (
          error instanceof AuthError &&
          error?.name === "NotAuthorizedException"
        )
          noti("このパスワードはすでに期限切れになっています。");
        else noti(error?.message || error.toString());
      });
  };
  return (
    <>
      <div className="flex-1 bg-white px-8 py-10">
        <BizKeyLOGO />
        <FormControl className="!mt-12">
          <Label htmlFor="" className="text-sm">
            確認コード
          </Label>
          <VerifyCodeInputControl id="code" name="code" control={control} />
        </FormControl>
        <FormControl className="!mt-3">
          <Button
            onClick={handleSubmit(handleLoginClick)}
            loading={isPending}
            disabled={!isValid}
          >
            ログイン
          </Button>
        </FormControl>
        <Return className="mt-2" onClick={() => setIsSendCode(false)} />
      </div>
      <Footer className="bg-white" />
    </>
  );
};
