import { cx } from "@emotion/css";
import type { TextareaHTMLAttributes } from "react";

import ArrowIcon from "@/assets/return-down.svg?react";
import { useModal } from "@/hooks";
import type { InputOption } from "@/types";

import { Popup } from "./Popup";

export interface MultiSelectInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  styleType?: "shadow" | "outline";
  options: InputOption[];
  // eslint-disable-next-line no-unused-vars
  handleOptionClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}
export const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
  styleType = "outline",
  options,
  handleOptionClick,
  ...props
}) => {
  const { visible, toggle } = useModal();
  return (
    <div className="relative mb-7">
      <ArrowIcon className="absolute right-3 top-0 z-20 my-4 h-4 w-4" />
      <div
        onClick={toggle}
        className={cx([
          "flex min-h-11 w-full flex-wrap gap-1",
          "placeholder:text-text-gray form-input rounded-lg p-3 text-sm outline-0",
          "focus:border-transparent focus:ring-primary",
          {
            "border-none  bg-[#F2F4F5] outline-gray-500":
              styleType === "shadow",
            "border-solid border-[#E3E5E5]": styleType === "outline",
          },
          "pr-8",
        ])}
      >
        <span className="text-neutral-100">
          {(props.value as [])?.length === 0 && props.placeholder}
        </span>
        {(props.value as [])?.map((item, index) => (
          <span
            key={index}
            className={cx(
              "rounded-sm border border-solid border-[#F0F0F0]",
              "inline-block h-7 bg-[#F5F5F5] px-2 py-1",
              "text-xs"
            )}
          >
            {item}
          </span>
        ))}
      </div>
      <textarea readOnly className="invisible absolute" {...props} />
      <Popup visible={visible} onOverlayClick={toggle}>
        <div
          className="divide-x-0 divide-y divide-solid divide-[#E9EDEE] p-4"
          onClick={handleOptionClick}
        >
          {options?.map((optionValue, index) => {
            const checked = (props.value as [])?.some(
              (item) => optionValue.label == item
            );
            return (
              <label
                key={index}
                className={cx(
                  "text-text-gray z-20 block text-center text-base leading-12"
                )}
                data-option-value={optionValue.value}
              >
                <input
                  className="-z-10"
                  type="checkbox"
                  value={optionValue.value}
                  checked={checked}
                />

                {optionValue.label}
              </label>
            );
          })}
        </div>
      </Popup>
    </div>
  );
};
