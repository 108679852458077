import { cx } from "@emotion/css";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddIcon from "@/assets/solid-add.svg?react";
import {
  BizKeyLOGO,
  Button,
  Card,
  Dialog,
  Footer,
  Loading,
  Main,
} from "@/components";
import { useModal } from "@/hooks";
import { useInfiniteListBizKey } from "@/queries";
import { setSelectApplicationId } from "@/redux/slices/bizkey";
import { noti } from "@/shared/utils";

import { EntryCodeDialog, InfiniteEntries } from "./components";

const EntriesList = () => {
  const navigate = useNavigate();
  const { visible, toggle } = useModal();
  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    error,
    isError: isGetBizkeysError,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteListBizKey("approved", true, true);
  const applications = useMemo(() => {
    return data?.pages;
  }, [data]);

  const handleDetailClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const buttonEle = e.target;
    if (
      buttonEle instanceof HTMLButtonElement &&
      buttonEle.dataset.applicationDetailId
    ) {
      navigate(`/application/${buttonEle.dataset.applicationDetailId}`);
    }
  };

  const handleUnlockClick = (applicationId: string) => {
    dispatch(setSelectApplicationId(applicationId));
    toggle();
  };

  if (isGetBizkeysError) {
    noti(error?.message || error.toString());
  }

  return (
    <>
      <Main>
        <BizKeyLOGO />
        <div className="flex flex-col gap-3">
          <Card>
            <header className="text-sm font-semibold text-black/70">
              事前・各種申請
            </header>
            <Button
              type="default"
              icon={<AddIcon />}
              onClick={() => navigate("/apply")}
              className="!my-2"
            >
              申請する
            </Button>
          </Card>
          <EntryIndexItem>
            <p>申請一覧</p>
            <button
              className="text-xs font-normal leading-6 text-primary"
              onClick={() => navigate("/application?status=awaiting")}
            >
              詳しく見る&nbsp;&gt;
            </button>
          </EntryIndexItem>
          <EntryIndexItem>
            <p>入館リスト （{applications?.[0].data.total ?? 0}件）</p>
            {isLoading ? (
              <Loading className="ml-auto mr-0 leading-4" color="#00afcc" />
            ) : (
              <button
                className="text-xs font-normal text-primary"
                onClick={() => refetch()}
              >
                リストを更新
              </button>
            )}
          </EntryIndexItem>
        </div>
        <div onClick={handleDetailClick}>
          <InfiniteEntries
            applications={applications}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            loading={isLoading}
            handleUnlockClick={handleUnlockClick}
          />
        </div>
      </Main>
      <Footer className="mt-7" />
      <Dialog visible={visible} onClose={toggle}>
        <div className="mt-6">
          <EntryCodeDialog visible={visible} />
        </div>
      </Dialog>
    </>
  );
};

const EntryIndexItem = ({
  children,
}: {
  children?: ReactNode | ReactNode[];
}) => {
  return (
    <Card
      className={cx(
        "flex justify-between py-4",
        "text-sm font-semibold leading-6 text-black/70"
      )}
    >
      {children}
    </Card>
  );
};

export default EntriesList;
