import { cx } from "@emotion/css";

interface StepIndicatorProps {
  active?: boolean;
  sequence: number;
  caption: string;
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  active,
  sequence,
  caption,
}) => {
  return (
    <div
      className={cx(active ? "text-primary" : "text-neutral-400", "text-xs")}
    >
      <span
        className={cx(
          "mx-auto block h-4 w-4 rounded-full  text-center text-xxs leading-4 text-white",
          active ? "bg-primary" : "bg-neutral-300"
        )}
      >
        {sequence}
      </span>
      <p className="leading-6">{caption}</p>
    </div>
  );
};
