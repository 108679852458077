import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { TOKYO_TIMEZONE } from "@/app.constant";
import { getWeekDaysOptionsRecord } from "@/shared/utils";
import type { BizKeyModal } from "@/types";

export function EntryTimeFormatter({
  application,
}: Readonly<{
  application: BizKeyModal;
}>) {
  const {
    start_date,
    time_start,
    time_end,
    access_method,
    valid_duration,
    day_of_week,
  } = application.template;
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const startDate = dayjs
    .utc(start_date)
    .tz(TOKYO_TIMEZONE)
    .format("YYYY年MM月DD日");
  const endDate = dayjs
    .utc(start_date)
    .tz(TOKYO_TIMEZONE)
    .add(valid_duration, "days")
    .format("MM月DD日");

  const startTime = dayjs.utc(time_start).format("HH:mm");
  const endTime = dayjs.utc(time_end).format("HH:mm");

  const weekDaysRecord = getWeekDaysOptionsRecord();
  const weekDays = day_of_week?.map((day) => weekDaysRecord[day]).join("、");
  if (access_method === "onetime") {
    return (
      <>
        <p>{`${startDate} ${startTime} - ${endTime}`}</p>
        <p className="mt-2 text-primary">一回限り</p>
      </>
    );
  } else
    return (
      <>
        <p>{`${startDate}-${endDate}`}</p>
        <div className="mt-2 text-primary">
          <p>{`毎週の${weekDays}`}</p>
          <p>{`${startTime}- ${endTime}`}</p>
          <p>定期的に</p>
        </div>
      </>
    );
}
