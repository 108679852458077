import { type Control, useController } from "react-hook-form";

import type { TextInputProps } from "@/components/form";
import { TextInput } from "@/components/form";
import { OnlyNumberRegExp, removeUnexpectedChar } from "@/shared/utils";

interface PhoneInputControlProps extends TextInputProps {
  name: string;
  control: Control<any>;
}
export const PhoneInputControl: React.FC<PhoneInputControlProps> = ({
  name,
  control,
  ...props
}) => {
  const {
    field: { value, onChange },
  } = useController({ name, control, rules: { required: true } });
  const hangleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = removeUnexpectedChar(e.target.value, OnlyNumberRegExp)
      .toString()
      .substring(0, 11);
    onChange(cleaned);
  };
  return <TextInput value={value} onChange={hangleChange} {...props} />;
};
