import { cx } from "@emotion/css";
import React from "react";

import ReturnIcon from "@/assets/return.svg?react";

interface ReturnProps {
  onClick?: () => void;
  className?: string;
}

export const Return: React.FC<ReturnProps> = ({ onClick, className }) => {
  return (
    <button
      className={cx("text-left text-sm font-semibold text-primary", className)}
      onClick={onClick}
    >
      <ReturnIcon className="mb-0.5 inline h-4 w-4" />
      戻る
    </button>
  );
};
